<template>
  <div class="page alert">
    <div class="top-header">
      <button class="header-btn btn-back" @click="$router.back()">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">알림 설정</h1>
    </div>
    <div class="page-content">
      <div class="grey-card mt-4 switch-card">
        <v-row>
          <v-col cols="8" class="col-title">주사 알림 보내기</v-col>
          <v-col cols="4">
            <v-switch
              v-model="allowInjectionNotice"
              inset
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </div>
      <h2 class="page-sub-title mt-8">
        횟수 : {{ numberOfInjectionsPerWeek }}일 / 주
      </h2>
      <!-- <ul>
        <li>투약 일정은 의사 선생님이 정합니다.</li>
        <li>투약 횟수를 변경하려면 의사 선생님께 문의 하십시오.</li>
      </ul> -->
      <v-row class="mt-6">
        <v-col cols="12">
          <!-- <TimePicker
            label="알람 시간"
            :time="setNoticeTime"
            @onchange="onchangeSetNoticeTime"
          /> -->
          <TimePickerInput
            label="알람 시간"
            :time="setNoticeTime"
            @onchange="onchangeSetNoticeTime"
          />
        </v-col>
      </v-row>
      <h2 class="page-sub-title mt-8">알람 요일</h2>
      <v-btn-toggle v-model="alertDay" class="alert-day" multiple rounded dense>
        <v-btn> 월 </v-btn>
        <v-btn> 화 </v-btn>
        <v-btn> 수 </v-btn>
        <v-btn> 목 </v-btn>
        <v-btn> 금 </v-btn>
        <v-btn> 토 </v-btn>
        <v-btn> 일 </v-btn>
      </v-btn-toggle>
      <!-- 투약알림 설정 추가 시작 -->
      <div class="grey-card mt-14 switch-card">
        <v-row>
          <v-col cols="8" class="col-title">투약기록 알림 보내기</v-col>
          <v-col cols="4">
            <v-switch
              v-model="allowNoInjectionNotice"
              inset
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </div>
      <v-row class="mt-2">
        <p class="row-guide">
          3일 이상 투약기록이 없을 경우 알림 메시지가 발송됩니다.
        </p>
      </v-row>
    </div>
    <!-- 투약알림 설정 추가 끝 -->
    <div class="bottom-btn">
      <v-btn color="primary" depressed large @click="clickSaveBtn"> 저장</v-btn>
    </div>
    <Common_Popup
      :openCloseStatus="commonPopupStatus"
      :text="commonPopupText"
      @onchangeOpenCloseStatus="onchangeOpenCloseStatus"
      @clickCloseBtn="clickCommonPopupCloseBtn"
    />
  </div>
</template>

<script>
import TimePicker from "@/components/common/vuetify/TimePicker.vue";
import TimePickerInput from "@/components/common/vuetify/TimePickerInput.vue";
import requiredText from "@/components/common/alertSetting/requiredText";
import Common_Popup from "@/components/common/popup/Common_Popup.vue";
import moment from "moment";
import store from "@/store/index";
export default {
  components: { TimePicker, TimePickerInput, Common_Popup },
  data() {
    return {
      /** 주사 알림 보내기 */
      allowInjectionNotice: true,
      /** 투약 기록 알림 보내기 */
      allowNoInjectionNotice: true,
      /** 알림 시간 */
      setNoticeTime: moment().format("HH:mm"),
      /** 알림 요일 */
      alertDay: [0, 1, 2],
      /** 수정시 필요한 index */
      injectionNoticeSettingIndex: null,
      /** 주 별 투약 일수 표시 */
      numberOfInjectionsPerWeek: null,
      /** 공통 팝업 */
      commonPopupStatus: false,
      commonPopupText: "",
    };
  },
  mounted() {
    this.getInjectionAlertData();
    this.getPrescription();
  },
  methods: {
    clickBack() {},
    clickSaveBtn() {
      // 필수 입력 체크
      if (this.checkRequired()) return;
      // insert인지 update인지 분리
      if (this.injectionNoticeSettingIndex) {
        this.updateInjectionNotice();
      } else {
        this.insertInjectionNotice();
      }
    },
    clickCommonPopupCloseBtn() {
      this.commonPopupStatus = false;
    },
    checkRequired() {
      const popupOpen = (columnName) => {
        this.commonPopupStatus = true;
        this.commonPopupText = requiredText[columnName];
        return true;
      };
      if (!this.setNoticeTime) return popupOpen("setNoticeTime");
    },
    onchangeOpenCloseStatus(value) {
      this.commonPopupStatus = value;
    },
    onchangeSetNoticeTime(value) {
      this.setNoticeTime = value;
    },
    /** 투약 알림 데이터 바인딩 */
    bindInjectionAlertData(res) {
      this.injectionNoticeSettingIndex = res.injection_notice_setting_index;
      this.allowInjectionNotice =
        res.allow_injection_notice === "Y" ? true : false;
      this.allowNoInjectionNotice =
        res.allow_no_injection_notice === "Y" ? true : false;
      this.setNoticeTime = res.set_notice_time;
      this.alertDay = [
        res.mon,
        res.tue,
        res.wed,
        res.thu,
        res.fri,
        res.sat,
        res.sun,
      ]
        .map((item, index) => {
          if (item === "Y") return index;
        })
        .filter((item) => item > -1);
    },
    /** 투약 알림 저장 (신규) */
    async insertInjectionNotice() {
      const facDayFormat = (dayOfTheWeek) => {
        return this.alertDay.includes(dayOfTheWeek) ? "Y" : "N";
      };
      try {
        const variable = {
          allowNoInjectionNotice: this.allowNoInjectionNotice ? "Y" : "N",
          allowInjectionNotice: this.allowInjectionNotice ? "Y" : "N",
          setNoticeTime: this.setNoticeTime,
          mon: facDayFormat(0),
          tue: facDayFormat(1),
          wed: facDayFormat(2),
          thu: facDayFormat(3),
          fri: facDayFormat(4),
          sat: facDayFormat(5),
          sun: facDayFormat(6),
        };
        await this.$store.dispatch("postInsertInjectionNotice", variable);
        console.log("저장완료");
        this.$router.push({ name: "app" });
      } catch (error) {
        console.log(error);
      }
    },
    /** 투약 알림 저장 (수정) */
    async updateInjectionNotice() {
      /**
       * @description 0(월요일) 을 넣으면 월요일을 체크했을경우 "Y" 아니면 "N" 을 리턴함.
       * @param {number} dayOfTheWeek - 0: 월요일, 1: 화요일 2: 수요일 ...
       */
      const facDayFormat = (dayOfTheWeek) => {
        return this.alertDay.includes(dayOfTheWeek) ? "Y" : "N";
      };
      try {
        const variable = {
          injectionNoticeSettingIndex: this.injectionNoticeSettingIndex,
          allowNoInjectionNotice: this.allowNoInjectionNotice ? "Y" : "N",
          allowInjectionNotice: this.allowInjectionNotice ? "Y" : "N",
          setNoticeTime: this.setNoticeTime,
          mon: facDayFormat(0),
          tue: facDayFormat(1),
          wed: facDayFormat(2),
          thu: facDayFormat(3),
          fri: facDayFormat(4),
          sat: facDayFormat(5),
          sun: facDayFormat(6),
        };
        await this.$store.dispatch("postUpdateInjectionNotice", variable);
        console.log("저장완료");
        this.$router.push({ name: "app" });
      } catch (error) {
        console.log(error);
      }
    },
    /** 투약 알림 데이터 조회 */
    async getInjectionAlertData() {
      try {
        const injectionAlertData = await this.$store.dispatch(
          "getInjectionNoticeData"
        );
        console.log(injectionAlertData);
        if (injectionAlertData) {
          this.bindInjectionAlertData(injectionAlertData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /** 처방 데이터 정보 바인딩 */
    async getPrescription() {
      try {
        const res = await store.dispatch("getLastPrescriptionData", {});
        this.numberOfInjectionsPerWeek = res.number_of_injections_per_week;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.alert {
  ul {
    padding-left: 40px;
    li {
      color: #555555;
      line-height: 24px;
    }
  }
  .alert-day.v-item-group.v-btn-toggle {
    width: 310px;
    display: block;
    margin: 0 auto;
    .v-btn {
      background: #f8f8f8;
      color: #555555;
      border: none !important;
      font-size: 14px;
      margin-right: 5px;
      border-radius: 20px;
      width: 40px;
      min-width: auto;
      height: 40px;
      &.v-item--active {
        background: $primary !important;
        color: #ffffff;
      }
      &::before {
        opacity: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>