<template>
  <v-dialog v-model="dialog" content-class="popup index-site">
    <v-card-title>추천되는 주사부위</v-card-title>
    <div class="popup-content">
      <v-row>
        <v-col cols="6">
          <img src="@/assets/images/site/site_abdomen.png" alt="" />
          <p>배꼽으로부터 3cm정도 떨어진 부분을 선택합니다.</p>
        </v-col>
        <v-col cols="6">
          <img src="@/assets/images/site/site_thigh.png" alt="" />
          <p>허벅지 앞쪽으로 살이 많은 부위를 선택합니다.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <img src="@/assets/images/site/site_butt.png" alt="" />
          <p>엉덩이의 위쪽 바깥 부분을 선택합니다.</p>
        </v-col>
        <v-col cols="6">
          <img src="@/assets/images/site/site_arm.png" alt="" />
          <p>팔의 위쪽으로 살이 많은 부분을 선택합니다.</p>
        </v-col>
      </v-row>
      <div class="caution">
        <v-icon class="c-red"> mdi-alert-octagon-outline</v-icon>
        <p>
          <b class="c-red">매일 주사 부위를 교체해 주세요.</b>
          <!-- <br />
          한 부위에 반복해 주사할 경우, 지방조직위축증이 발생할 수 있습니다! -->
        </p>
      </div>

      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.index-site {
  .v-card__title {
    padding: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 16px;
  }
  .row {
    .col {
      img {
        border-radius: 16px;
        border: 1px solid $secondary;
      }
      p {
        color: $secondary;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  }
  .caution {
    p {
      display: inline-block;
      vertical-align: top;
      padding-left: 10px;
      width: calc(100% - 24px);
      word-break: keep-all;
    }
  }
}
</style>