<template>
  <v-dialog v-model="dialog" content-class="popup">
    <div class="popup-content">
      <h6 class="c-red">매일 주사 부위를 교체해 주세요.</h6>
      <br />
      <!-- <p>한 부위에 반복해 주사할 경우, 지방조직 위축증이 발생할 수 있습니다.</p> -->
      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.common {
}
</style>