<template>
  <div class="page-detail injection">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-close </v-icon>
      </button>
      <h1 class="detail-title">싸이젠 투약 기록하기</h1>
    </div>
    <div class="detail-content">
      <v-row v-if="$route.params.enterFromDiary !== 'update'">
        <v-col cols="6">
          <DatePicker
            label="투약일"
            :date="injectionDate"
            @onchange="onchangeInjectionDate"
            :allowedDateRule="(val) => val <= $moment().format('YYYY-MM-DD')"
          />
        </v-col>
        <v-col cols="6">
          <TimePickerInput
            label="투약시간 (24시간제)"
            :time="injectionTime"
            @onchange="onchangeInjectionTime"
          />
          <!-- <TimePicker
            label="투약 시간"
            :time="injectionTime"
            @onchange="onchangeInjectionTime"
          /> -->
        </v-col>
      </v-row>
      <v-row v-else>
        <h5>{{ computedInjectDate }}</h5>
      </v-row>
      <div v-if="!isBreakDay">
        <div class="grey-card mt-4 switch-card">
          <v-row>
            <v-col cols="8" class="col-title">투약 여부</v-col>
            <v-col cols="4">
              <v-switch v-model="isInjected" inset hide-details></v-switch>
            </v-col>
          </v-row>
        </div>
        <div v-if="isInjected">
          <div class="grey-card mt-4 switch-card">
            <v-row>
              <v-col cols="8" class="col-title">카트리지 교체 여부</v-col>
              <v-col cols="4">
                <v-switch
                  v-model="isReplacedCartridge"
                  inset
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </div>
          <div class="grey-card mt-4 prescription-capacity">
            <div class="card-title">처방 용량</div>
            <v-row>
              <v-col cols="6" class="text-center c-prm capacity">
                {{ PrescriptionDose }}<span>IU</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="6" class="text-center c-prm capacity">
                {{ computedDosage }}<span>mg</span>
              </v-col>
            </v-row>
            <p>
              처방 용량이 변경된 경우
              <span class="c-sec">[나의 정보]</span> 에서 수정해주세요.
            </p>
          </div>
          <div class="grey-card mt-4 switch-card">
            <v-row>
              <v-col cols="8" class="col-title">분할 주사</v-col>
              <v-col cols="4">
                <v-switch
                  v-model="splitInjection"
                  @change="onchangeSplitInjection"
                  inset
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </div>
          <div class="injection-site mt-4">
            <div class="guide-btn-wrap">
              <v-btn
                color="primary"
                class="btn-guide"
                @click="clickInjectionSiteGuideBtn"
                small
                depressed
              >
                주사부위 확인하기
              </v-btn>
            </div>
            <div class="body">
              <v-btn-toggle
                v-model="selectedSite"
                tile
                group
                :multiple="splitInjection"
              >
                <!-- 팔 -->
                <v-btn
                  class="btn-arm arm1"
                  :class="setDosingSiteStyle('arm1')"
                  small
                  outlined
                  fab
                  disabled
                >
                </v-btn>
                <v-btn
                  class="btn-arm arm2"
                  :class="setDosingSiteStyle('arm2')"
                  small
                  outlined
                  fab
                  disabled
                >
                </v-btn>

                <!-- 복부 -->
                <v-btn
                  class="btn-abdomen abdomen1"
                  :class="setDosingSiteStyle('abdomen1')"
                  small
                  outlined
                  fab
                  disabled
                >
                  1
                </v-btn>
                <v-btn
                  class="btn-abdomen abdomen2"
                  :class="setDosingSiteStyle('abdomen2')"
                  small
                  outlined
                  fab
                  disabled
                >
                  2
                </v-btn>
                <v-btn
                  class="btn-abdomen abdomen3"
                  :class="setDosingSiteStyle('abdomen3')"
                  small
                  outlined
                  fab
                  disabled
                >
                  3
                </v-btn>
                <v-btn
                  class="btn-abdomen abdomen4"
                  :class="setDosingSiteStyle('abdomen4')"
                  small
                  outlined
                  fab
                  disabled
                >
                  4
                </v-btn>

                <!-- 엉덩이 -->
                <v-btn
                  class="btn-butt butt1"
                  :class="setDosingSiteStyle('butt1')"
                  small
                  outlined
                  fab
                  disabled
                >
                </v-btn>
                <v-btn
                  class="btn-butt butt2"
                  :class="setDosingSiteStyle('butt2')"
                  small
                  outlined
                  fab
                  disabled
                >
                </v-btn>

                <!-- 허벅지 -->
                <v-btn
                  class="btn-thigh thigh1"
                  :class="setDosingSiteStyle('thigh1')"
                  small
                  outlined
                  fab
                  disabled
                >
                  1
                </v-btn>
                <v-btn
                  class="btn-thigh thigh2"
                  :class="setDosingSiteStyle('thigh2')"
                  small
                  outlined
                  fab
                  disabled
                >
                  2
                </v-btn>
                <v-btn
                  class="btn-thigh thigh3"
                  :class="setDosingSiteStyle('thigh3')"
                  small
                  outlined
                  fab
                  disabled
                >
                  3
                </v-btn>
                <v-btn
                  class="btn-thigh thigh4"
                  :class="setDosingSiteStyle('thigh4')"
                  small
                  outlined
                  fab
                  disabled
                >
                  4
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
          <div class="injection-group">
            <v-row class="injection-row mt-4">
              <v-col cols="12">
                <v-text-field
                  label="투약 용량"
                  type="number"
                  v-model="dosingCapacity"
                  @input="onchangeDosingCapacity"
                  onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  outlined
                  dense
                  hide-details
                  suffix="mg"
                />
              </v-col>
            </v-row>
            <v-row class="injection-row">
              <v-col cols="12">
                <v-select
                  label="주사부위"
                  v-model="dosingSite"
                  :items="itemsSite"
                  @input="onchangeDosingSite"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div class="injection-group" v-if="splitInjection">
            <v-divider></v-divider>
            <v-row class="injection-row">
              <v-col cols="12">
                <v-text-field
                  label="투약 용량"
                  type="number"
                  v-model="dosingCapacity2"
                  @input="onchangeDosingCapacity2"
                  onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  outlined
                  dense
                  hide-details
                  suffix="mg"
                />
              </v-col>
            </v-row>
            <v-row class="injection-row">
              <v-col cols="12">
                <v-select
                  label="주사 부위"
                  v-model="dosingSite2"
                  @input="onchangeDosingSite2"
                  :items="itemsSite"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div class="grey-card mt-4 today-grow">
            <div class="card-title">오늘의 성장 캐릭터</div>
            <v-btn-toggle v-model="selectedEmotion" tile group>
              <v-btn class="btn-emotion" outlined fab>
                <img src="@/assets/images/emotion/best.png" alt="" />
                <p>최고예요</p>
              </v-btn>
              <v-btn class="btn-emotion" outlined fab>
                <img src="@/assets/images/emotion/good.png" alt="" />
                <p>잘했어요</p>
              </v-btn>
              <v-btn class="btn-emotion" outlined fab>
                <img src="@/assets/images/emotion/notbad.png" alt="" />
                <p>괜찮아요</p>
              </v-btn>
              <v-btn class="btn-emotion" outlined fab>
                <img src="@/assets/images/emotion/bad.png" alt="" />
                <p>힘들었어요</p>
              </v-btn>
            </v-btn-toggle>
            <!-- <div class="memo mt-2">
              <div class="sticker-wrap">
                <img
                  class="emotion-sticker"
                  :src="
                    selectedEmotion !== undefined
                      ? require(`@/assets/images/emotion/${emotion[selectedEmotion]}.png`)
                      : ''
                  "
                  alt=""
                />
              </div>

              <v-textarea
                name="input-7-1"
                v-model="TodayGrowth"
                placeholder="오늘은 주사를 아주 잘 맞았어요. 기특해요!!"
                rows="2"
                auto-grow
                hide-details
              ></v-textarea>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <v-btn
        color="primary"
        depressed
        large
        :disabled="isBreakDay"
        @click="clickSaveBtn"
      >
        저장</v-btn
      >
    </div>
    <IndexSitePopup ref="IndexSitePopup" />
    <AlertDuplicatePopup ref="AlertDuplicatePopup" />
    <Common_Popup
      :openCloseStatus="commonPopupStatus"
      :text="commonPopupText"
      @onchangeOpenCloseStatus="onchangeOpenCloseStatus"
      @clickCloseBtn="clickCommonPopupCloseBtn"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/common/vuetify/DatePicker.vue";
import TimePicker from "@/components/common/vuetify/TimePicker.vue";
import IndexSitePopup from "@/components/common/popup/IndexSite_Popup.vue";
import AlertDuplicatePopup from "@/components/common/popup/AlertDuplicate_Popup.vue";
import Common_Popup from "@/components/common/popup/Common_Popup.vue";
import requiredText from "@/components/common/injectionDetail/requiredText";
import { itemsSite } from "@/utils/selectItems";
import moment from "moment";
import store from "@/store/index";
import TimePickerInput from "@/components/common/vuetify/TimePickerInput.vue";
export default {
  components: {
    DatePicker,
    TimePicker,
    IndexSitePopup,
    AlertDuplicatePopup,
    Common_Popup,
    TimePickerInput,
  },
  data() {
    return {
      /** 투약 일 */
      injectionDate: moment().format("YYYY-MM-DD"),
      /** 투약 시간 */
      injectionTime: moment().format("HH:mm"),
      /** 투약 여부 */
      isInjected: true,
      /** 카트리지 교체 여부 */
      isReplacedCartridge: false,
      // 처방 용량
      PrescriptionDose: null, // IU
      dosage: null, // mg
      /** 분할 주사 여부 */
      splitInjection: false, //  분할 주사 여부

      itemsSite,
      dosingCapacity: null, // 투약 용량
      dosingSite: null, // 투약 위치
      dosingCapacity2: null, // 투약 용량2
      dosingSite2: null, // 투약 위치2

      selectedSite: null,
      selectedEmotion: 0,
      TodayGrowth: null,
      // 공통 팝업
      commonPopupStatus: false,
      commonPopupText: "",

      // 요일별 투약 용량
      amountInjection: [],
      injectionTool: null,
      prescriptionIndex: null,

      // 이전 투약 위치
      lastInjectionSite: [],
      // Flag
      isBreakDay: false, // 휴약 여부 true : 휴약일
      modifiedAlertDuplicatePopup: false,

      siteName: [
        "arm1",
        "arm2",
        "abdomen1",
        "abdomen2",
        "abdomen3",
        "abdomen4",
        "butt1",
        "butt2",
        "thigh1",
        "thigh2",
        "thigh3",
        "thigh4",
      ],
      emotion: ["best", "good", "notbad", "bad"],
    };
  },
  computed: {
    computedDosage() {
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const index = days.indexOf(moment(this.injectionDate).format("ddd"));
      console.log(days.indexOf(moment(this.injectionDate).format("ddd")));
      return this.amountInjection[index];
    },
    computedInjectDate() {
      return moment(this.injectionDate).format("YYYY년 M월 DD일 HH시 mm분");
    },
  },
  async mounted() {
    // 투약기록이 없는 경우 또는 있으나 투약하지않음으로 저장했을경우
    if (this.$route.params.enterFromDiary === "insert") {
      this.injectionDate = this.$route.params.date;
      // 투약기록이 있는 경우
    } else if (this.$route.params.enterFromDiary === "update") {
      this.getInjectionDetail(this.$route.params.injectionIndex);
    } else {
      await this.checkInjectionOrNot(); // 상단에 투약기록하기 버튼 눌렀을 경우
    }
    await this.bindPrescription();
    await this.checkLastInjection();
    // await this.checkBreakDay();
  },
  methods: {
    clickBackBtn() {
      this.$router.push("injection");
    },
    /** @deprecated 이유: UI 변경으로 인해 사용하지 않음. 10.18 */
    clickSiteBtn() {
      if (this.splitInjection) {
        // 분할주사일 경우
        if (this.selectedSite.length > 2) {
          this.commonPopupStatus = true;
          this.commonPopupText =
            "주사 부위는 2곳만 선택이 가능합니다. 다른 주사 부위 선택을 원하실 경우 선택한 부위를 클릭하여 해제 후 선택해주세요.";
          this.selectedSite.pop();
        }
        const result = this.selectedSite.map((item) => {
          return (item = this.siteName[item]);
        });
        // console.log(result);
        this.dosingSite = result[0];
        this.dosingSite2 = result[1];
      } else {
        console.log(this.siteName[this.selectedSite]);
        this.dosingSite = this.siteName[this.selectedSite];
      }
    },
    clickSaveBtn() {
      // 필수 입력 체크
      if (this.checkRequired()) return;
      // insert , update 구분
      if (
        this.$route.params.enterFromDiary === "update" ||
        this.$route.params.injectionIndex // 투약하지않음 저장할경우
      ) {
        this.updateInjection();
      } else {
        this.saveInjection();
      }
    },
    clickInjectionSiteGuideBtn() {
      this.$refs.IndexSitePopup.Open();
    },
    clickCommonPopupCloseBtn() {
      this.commonPopupStatus = false;
      // 투약기록이 있는 날짜 팝업이 뜰 경우 확인버튼 누르면 투약 다이어리 화면으로 이동.
      const isInjectedText =
        "이미 투약 기록이 있는 날짜입니다. 수정을 원하시면, 투약다이어리 달력에서 일자를 선택 후 수정해주세요";
      if (isInjectedText === this.commonPopupText) {
        this.$router.push("injection");
      }
    },
    async onchangeInjectionDate(value) {
      this.injectionDate = value;
      this.checkInjectionOrNot();
      this.checkLastInjection();
      await this.bindPrescription();
      // this.checkBreakDay();
      this.modifiedAlertDuplicatePopup = false;
    },
    onchangeInjectionTime(value) {
      this.injectionTime = value;
    },
    onchangeDosingSite(value) {
      // const index = this.siteName.indexOf(value);
      // if (this.splitInjection) {
      //   console.log(this.selectedSite, index);
      //   this.selectedSite[0] = index; // toggle 버튼에 업데이트가 되지않음...
      // } else {
      //   this.selectedSite = index; // toggle 버튼에 업데이트가 됨
      // }
    },
    onchangeDosingSite2(value) {
      // const index = this.siteName.indexOf(value);
      // console.log(index);
      // this.selectedSite[1] = index; // toggle 버튼에 업데이트가 되지않음...
      // console.log(this.selectedSite);
    },
    onchangeDosingCapacity(value) {
      let pattern;
      if (this.injectionTool === "easyPod") {
        pattern = /^\d*.?\d{0,2}$/;
      } else {
        pattern = /^\d*.?\d{0,1}$/;
      }
      if (!pattern.test(value)) this.dosingCapacity = null;
    },
    onchangeDosingCapacity2(value) {
      // this.dosingCapacity2 = value;
      let pattern;
      if (this.injectionTool === "easyPod") {
        pattern = /^\d*.?\d{0,2}$/;
      } else {
        pattern = /^\d*.?\d{0,1}$/;
      }
      if (!pattern.test(value)) this.dosingCapacity2 = null;
    },
    onchangeSplitInjection() {
      // if (!this.splitInjection) {
      this.dosingSite = null;
      this.dosingSite2 = null;
      this.dosingCapacity = null;
      this.dosingCapacity2 = null;
      // }
    },
    onchangeOpenCloseStatus(value) {
      this.commonPopupStatus = value;
    },
    updateValue() {},
    /** 투약기록 디테일 조회 */
    async getInjectionDetail(injectionIndex) {
      try {
        const variable = {
          injectionIndex,
        };
        const res = await store.dispatch("getInjectionDetail", variable);
        console.log(res);
        this.bindInjectionDetail(res);
      } catch (error) {
        console.log(error);
      }
    },
    /** 버튼스타일 반영 */
    setDosingSiteStyle(target) {
      return {
        "v-item--active":
          target == this.dosingSite || target == this.dosingSite2,
      };
    },
    /** 투약기록 정보 바인딩 */
    bindInjectionDetail(res) {
      this.injectionDate = this.$moment(res.injection_datetime).format(
        "YYYY-MM-DD HH:mm"
      );
      this.injectionTime = this.$moment(res.injection_datetime).format("HH:mm");
      this.isInjected = res.is_injected === "Y" ? true : false;
      this.isReplacedCartridge =
        res.is_replaced_cartridge === "Y" ? true : false;
      this.splitInjection = res.is_two_injections === "Y" ? true : false;
      this.dosingSite = res.injected_body_part;
      this.dosingCapacity = res.injection_dose;
      this.dosingSite2 = res.injected_body_part_2nd
        ? res.injected_body_part_2nd
        : null;
      this.dosingCapacity2 =
        res.injection_dose_2nd == -1 ? null : res.injection_dose_2nd;
      this.TodayGrowth = res.injection_review;
      this.selectedEmotion = this.emotion.indexOf(res.injection_emoticon);
      // if (this.splitInjection) {
      //   this.selectedSite = [
      //     this.siteName.indexOf(this.dosingSite),
      //     this.siteName.indexOf(this.dosingSite2),
      //   ];
      // } else {
      //   this.selectedSite = this.siteName.indexOf(this.dosingSite);
      // }
    },
    /** 마지막 처방정보 찾기 */
    findLastPrescription(firstInjectionDate, data, injectionDate) {
      // step0. 첫처방정보 입력일자가 없을 경우 또는 첫 주사시작일 이전일 경우 -> 최초 처방정보 입력일자 이전 달을 요청하면, 마지막 처방일을 가져오지 못함. -> 저장 불가하게 되어야함
      const injectionDateIsBeforeFirstInjectionDate = this.$moment(
        injectionDate
      ).isBefore(this.$moment(firstInjectionDate));
      if (data.length < 1 || injectionDateIsBeforeFirstInjectionDate) {
        this.isBreakDay = true;
        return;
      }
      // step0-1. 첫 처방정보 입력일자 찾기
      const firstPrescriptionDate = data.reduce((previous, current) => {
        const previousIsBeforeCurrent = this.$moment(
          previous.prescription_date
        ).isSameOrBefore(this.$moment(current.prescription_date))
          ? previous
          : current;
        return previousIsBeforeCurrent;
      });
      // console.log("firstPrescriptionDate: ", firstPrescriptionDate);
      // step1. 첫처방정보 입력일자가 선택 날짜보다 이후 일경우 -> 저장 불가하게 되어야 함.
      const isAfterFirstPrescriptionDate = this.$moment(
        firstPrescriptionDate.prescription_date
      ).isAfter(this.$moment(injectionDate));
      // console.log(isAfterFirstPrescriptionDate);
      if (isAfterFirstPrescriptionDate) {
        this.isBreakDay = true;
        console.log(this.isBreakDay);
        return;
      }
      // step2. 선택날짜의 이전날짜이면서 (filter, isSameOrBefore)
      const isBeforePrescriptionDate = data.filter((item) => {
        this.$moment(item.prescription_date).isSameOrBefore(
          this.$moment(injectionDate)
        );
        return item;
      });
      // console.log("isBeforePrescriptionDate: ", isBeforePrescriptionDate);

      // 만약 같은날에 여러 처방일이 있을경우 prescription_index 값이 높은 데이터로 지정
      // const distinctPrescription = isBeforePrescriptionDate.filter(
      //   (element, index, arr) => {
      //     return (
      //       arr.findIndex(
      //         (item) => item.prescription_date === element.prescription_date
      //       ) === index
      //     );
      //   }
      // );
      // step3. 만약 같은날에 여러 처방일이 있을경우 prescription_index 값이 높은 데이터로 지정
      const distinctPrescription = isBeforePrescriptionDate.reduce(
        (acc, currentValue) => {
          const index = acc.findIndex(
            (item) => item.prescription_date === currentValue.prescription_date
          );
          if (index > -1) {
            return acc[index].prescription_index >
              currentValue.prescription_index
              ? acc
              : (acc[index] = currentValue);
          } else {
            return [...acc, currentValue];
          }
        },
        []
      );
      console.log("distinctPrescription: ", distinctPrescription);
      // step4. 제일 가까운 날짜 (reduce) 찾기
      const theNearestDate = distinctPrescription.reduce(
        (previous, current) => {
          const previousDiff = this.$moment(previous.prescription_date).diff(
            this.$moment(injectionDate),
            "days"
          );
          const currentDiff = this.$moment(current.prescription_date).diff(
            this.$moment(injectionDate),
            "days"
          );
          return Math.abs(previousDiff) < Math.abs(currentDiff)
            ? previous
            : current;
        }
      );
      console.log("theNearestDate: ", theNearestDate);

      return theNearestDate;
    },
    /** 처방 데이터 정보 바인딩 */
    async bindPrescription() {
      const variable = {
        targetYear: this.$moment(this.injectionDate).format("YYYY"),
        targetMonth: this.$moment(this.injectionDate).format("M"),
      };
      const prescription = await store.dispatch(
        "getLastPrescriptionData",
        variable
      );
      const { first_injection_date } = await store.dispatch("getMyInfo");
      // console.log("getLastPrescriptionData: ", prescription);
      // console.log("getMyInfo: ", first_injection_date);
      // 처방정보 데이터 가 여러개일경우 지정해서 맞추어줘야함.
      const lastPrescription = this.findLastPrescription(
        first_injection_date,
        prescription,
        this.injectionDate
      );
      if (!lastPrescription) return;

      this.PrescriptionDose = lastPrescription.daily_injection_dose;
      this.amountInjection = [
        lastPrescription.mon,
        lastPrescription.tue,
        lastPrescription.wed,
        lastPrescription.thu,
        lastPrescription.fri,
        lastPrescription.sat,
        lastPrescription.sun,
      ];
      this.injectionTool = lastPrescription.injection_tool;
      this.prescriptionIndex = lastPrescription.prescription_index;
      this.checkBreakDay();
      console.log(this.amountInjection);
    },
    checkRequired() {
      const popupOpen = (columnName) => {
        this.commonPopupStatus = true;
        this.commonPopupText = requiredText[columnName];
        return true;
      };
      // 투약 여부 on /off 에 따라 분리
      if (this.isInjected) {
        if (!this.injectionDate) return popupOpen("injectionDate");
        if (!this.injectionTime) return popupOpen("injectionTime");
        // 분할 주사 여부 on /off 에 따라 분리
        if (this.splitInjection) {
          if (!this.dosingCapacity) return popupOpen("dosingCapacity");
          if (!this.dosingSite) return popupOpen("dosingSite");
          if (!this.dosingCapacity2) return popupOpen("dosingCapacity2");
          if (!this.dosingSite2) return popupOpen("dosingSite2");
          if (this.dosingSite === this.dosingSite2) {
            if (!this.modifiedAlertDuplicatePopup) {
              this.$refs.AlertDuplicatePopup.Open();
              this.modifiedAlertDuplicatePopup = true;
              return true;
            }
          }
        } else {
          if (!this.dosingCapacity) return popupOpen("dosingCapacity");
          if (!this.dosingSite) return popupOpen("dosingSite");
        }
        if (this.checkInjectedOverlap()) {
          if (!this.modifiedAlertDuplicatePopup) {
            this.$refs.AlertDuplicatePopup.Open();
            this.modifiedAlertDuplicatePopup = true;
            return true;
          }
        }
        if (this.selectedEmotion === undefined)
          return popupOpen("selectedEmotion");
      }
      return false;
    },
    /** 휴약일인지 확인 */
    checkBreakDay() {
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const index = days.indexOf(moment(this.injectionDate).format("ddd"));
      const isBreakDay = this.amountInjection[index] == -1;
      if (isBreakDay) {
        // 휴약일을 선택한 경우 ”선택한 날짜는 휴약일 입니다. 투약일을 확인해주세요.” 팝업을 띄운 후 ((2)번에서 (9)번까지)가림 또는 비활성화 처리
        this.commonPopupStatus = true;
        this.commonPopupText =
          "선택한 날짜는 휴약일 입니다. 투약일을 확인해주세요.";
        this.isBreakDay = true;
      } else {
        this.isBreakDay = false;
      }
    },
    /** 투약 기록 저장 */
    async saveInjection() {
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const daysEn = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      const index = days.indexOf(moment(this.injectionDate).format("ddd"));

      //TODO 추후에 모델화
      const variable = {
        prescriptionIndex: this.prescriptionIndex,
        injectionDatetime: `${this.injectionDate} ${this.injectionTime}`,
        isInjected: this.isInjected ? "Y" : "N",
        isReplacedCartridge: this.isReplacedCartridge ? "Y" : "N",
        injectionWeekday: daysEn[index],
        isTwoInjections: this.splitInjection ? "Y" : "N",
        injectionDose: this.dosingCapacity,
        injectedBodyPart: this.dosingSite,
        injectionDose_2nd: this.dosingCapacity2,
        injectedBodyPart_2nd: this.dosingSite2,
        injectionEmoticon: this.emotion[this.selectedEmotion],
        injectionReview: this.TodayGrowth,
        injectedStatus: this.checkInjectedStatus(),
        prescriptionDose: this.checkPrescriptionDose(),
        isInjectedOverlap: this.checkInjectedOverlap() ? "Y" : "N",
      };
      console.log(variable);
      try {
        await store.dispatch("postInjection", variable);
        console.log("저장완료");
        this.$router.push({ name: "app" });
      } catch (error) {
        console.log(error);
      }
    },
    /** 투약 기록 수정 */
    async updateInjection() {
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const daysEn = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      const index = days.indexOf(moment(this.injectionDate).format("ddd"));
      const injectionIndex = this.$route.params.injectionIndex;
      //TODO 추후에 모델화
      const variable = {
        injectionIndex,
        prescriptionIndex: this.prescriptionIndex,
        injectionDatetime: `${this.$moment(this.injectionDate).format(
          "YYYY-MM-DD"
        )} ${this.injectionTime}`,
        isInjected: this.isInjected ? "Y" : "N",
        isReplacedCartridge: this.isReplacedCartridge ? "Y" : "N",
        injectionWeekday: daysEn[index],
        isTwoInjections: this.splitInjection ? "Y" : "N",
        injectionDose: this.dosingCapacity,
        injectedBodyPart: this.dosingSite,
        injectionDose_2nd: this.dosingCapacity2,
        injectedBodyPart_2nd: this.dosingSite2,
        injectionEmoticon: this.emotion[this.selectedEmotion],
        injectionReview: this.TodayGrowth,
        injectedStatus: this.checkInjectedStatus(),
        prescriptionDose: this.checkPrescriptionDose(),
        isInjectedOverlap: this.checkInjectedOverlap() ? "Y" : "N",
      };
      console.log(variable);
      try {
        await store.dispatch("postUpdateInjection", variable);
        console.log("저장완료");
        this.$router.push({ name: "app" });
      } catch (error) {
        console.log(error);
      }
    },
    /** 마지막 주사 부위 중복인지 체크하기 */
    checkInjectedOverlap() {
      // 투약 위치 중복되는지 체크하기 (이전 데이터와 비교)
      if (
        this.lastInjectionSite.indexOf(this.dosingSite) > -1 ||
        this.lastInjectionSite.indexOf(this.dosingSite2) > -1
      ) {
        return true;
      }
      return false;
    },
    checkPrescriptionDose() {
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const index = days.indexOf(moment(this.injectionDate).format("ddd"));
      return this.amountInjection[index];
    },
    checkInjectedStatus() {
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const index = days.indexOf(moment(this.injectionDate).format("ddd"));
      const isEqual =
        (Number(this.dosingCapacity) + Number(this.dosingCapacity2)).toFixed(
          2
        ) == this.amountInjection[index];
      console.log(
        (Number(this.dosingCapacity) + Number(this.dosingCapacity2)).toFixed(2),
        this.amountInjection[index]
      );
      return isEqual ? "complete" : "incomplete";
    },
    /** 이미 투약 기록이 있는 날짜 확인 */
    async checkInjectionOrNot() {
      const variable = {
        targetDate: this.injectionDate,
      };
      try {
        const res = await store.dispatch("getInjectionLog", variable);
        const isInjection = res.exist_injection_log === "Y";
        if (isInjection) {
          this.commonPopupStatus = true;
          this.commonPopupText =
            "이미 투약기록이 있습니다. 변경이 필요할 경우, 투약 다이어리 달력에서 날짜를 선택한 후 변경해 주세요.";
          // 10.12 오늘날짜로 이동되었을때 오늘날짜에도 투약기록이 있는경우에는 반복되기 때문에 팝업 띄우고 확인 버튼 누르면 다이어리 화면으로 돌아가도록 수정 필요
          // (이동되는 날인 오늘이 휴약일일때 ) 바로 화면 이동되면 어색하기때문에 시간차를 주고 날짜 이동
          // setTimeout(() => {
          //   this.onchangeInjectionDate(this.$moment().format("YYYY-MM-DD"));
          // }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /** 마지막에 주사 부위 확인 */
    async checkLastInjection() {
      const variable = {
        targetDate: this.injectionDate,
      };
      try {
        const res = await store.dispatch("getLastInjection", variable);
        console.log(res);
        this.lastInjectionSite = [];
        if (res.length > 0) {
          // 선택한 날짜 앞뒤로 투약한 부위 저장
          res.forEach((item) => {
            if (
              item.injected_body_part &&
              !this.lastInjectionSite.includes(item.injected_body_part)
            ) {
              this.lastInjectionSite.push(item.injected_body_part);
            }
            if (
              item.injected_body_part_2nd &&
              !this.lastInjectionSite.includes(item.injected_body_part_2nd)
            ) {
              this.lastInjectionSite.push(item.injected_body_part_2nd);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.page-detail.injection {
  .prescription-capacity {
    .capacity {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 32px;
      span {
        display: inline-block;
        margin-left: 8px;
        font-weight: 400;
        font-size: 12px;
      }
    }
    p {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  .injection-site {
    .body {
      width: 320px;
      margin: 0 auto;
      height: 370px;
      background: url(~@/assets/images/character/body2.png) no-repeat;
      background-size: auto 370px;
      background-position: center;
      .v-btn-toggle--group {
        position: relative;
        .v-btn {
          position: absolute;
          border: 2px solid $primary;
          border-radius: 20px;
          width: 26px;
          height: 26px;
          padding: 0;
          margin: 0;
          &.v-item--active {
            background: $primary !important;
          }
          // 팔
          &.btn-arm {
            border-color: #ffcc00;
            background-color: rgba(255, 204, 0, 0.3) !important;
            &::before {
              opacity: 0;
            }
            &.v-item--active {
              background: #ffcc00 !important;
              &::before {
                opacity: 0;
              }
            }
            &.arm1 {
              top: 160px;
              left: 109px;
            }
            &.arm2 {
              top: 160px;
              left: 196px;
            }
          }
          // 복부
          &.btn-abdomen {
            border-color: #00a3ff;
            background-color: rgba(0, 162, 255, 0.3) !important;
            &::before {
              opacity: 0;
            }
            &.v-item--active {
              background: #00a3ff !important;
              &::before {
                opacity: 0;
              }
            }
            &.abdomen1 {
              top: 180px;
              left: 138px;
            }
            &.abdomen2 {
              top: 180px;
              left: 166px;
            }
            &.abdomen3 {
              top: 208px;
              left: 138px;
            }
            &.abdomen4 {
              top: 208px;
              left: 166px;
            }
          }
          // 엉덩이
          &.btn-butt {
            border-color: #7dc900;
            background-color: rgba(124, 201, 0, 0.3) !important;
            &::before {
              opacity: 0;
            }
            &.v-item--active {
              background: #7dc900 !important;
              &::before {
                opacity: 0;
              }
            }
            &.butt1 {
              top: 225px;
              left: 117px;
            }
            &.butt2 {
              top: 225px;
              left: 187px;
            }
          }
          // 허벅지
          &.btn-thigh {
            border-color: #ff43b4;
            background-color: rgba(255, 67, 180, 0.3) !important;
            &::before {
              opacity: 0;
            }
            &.v-item--active {
              background: #ff43b4 !important;
              &::before {
                opacity: 0;
              }
            }
            &.thigh1 {
              top: 252px;
              left: 115px;
            }
            &.thigh2 {
              top: 251px;
              left: 187px;
            }
            &.thigh3 {
              top: 279px;
              left: 115px;
            }
            &.thigh4 {
              top: 279px;
              left: 187px;
            }
          }
        }
      }
    }
  }
  .injection-group {
    .v-divider {
      margin: 10px;
    }
  }

  .today-grow {
    .v-btn-toggle--group {
      text-align: center;
      display: block;
      .btn-emotion {
        height: 65px !important;
        border-radius: 8px !important;
        .v-btn {
          opacity: 1;
        }
        &.v-item--active {
          background: #ffffff !important;
        }
        &::before {
          opacity: 0;
        }
        .v-btn__content {
          display: block;
          text-align: center;
        }
        img {
          width: 30px;
        }
        p {
          font-size: 10px;
          margin: 0;
        }
      }
    }
    .memo {
      height: 80px;
      background: #ffffff;
      border: 1px solid #c8c8c8;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 10px;
      .sticker-wrap {
        padding: 0 15px 0 10px;
        .emotion-sticker {
          width: 30px;
        }
      }

      .v-textarea {
        padding: 0;
        margin: 0;
        .v-input__slot:before {
          border: none;
        }
        .v-input__slot::after {
          border: none;
        }
      }
    }
  }
}
</style>