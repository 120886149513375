<template>
  <div>
    <!-- <v-card-title>
      Medical Alert
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title> -->
    <v-data-table
      v-model="selected"
      :headers="columns"
      :items="inputData"
      :search="search"
      item-key="mrn"
      :show-select="showSelect"
      @click:row="clickRow"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    search: "",
    columns: [],
    inputData: [],
    showSelect: "",
  },
  watch: {
    search() {
      // console.log(this.search);
    },
  },
  data() {
    return {
      // search: "",
      selected: [],
      singleSelect: false,
    };
  },
  methods: {
    clickRow(value) {
      console.log(value.mrn);
      this.$router.push("/app/patient/patientRead");
    }
  }
};
</script>

<style>
</style>
