<template>
  <div>
    <v-combobox
      v-model="select"
      :items="itemsOne"
      :label="label"
      multiple
      dense
    />
  </div>
</template>

<script>
export default {
  props: {
    label: "",
  },
  data() {
    return {
      select: ["Vuetify", "Programming"],
      itemsOne: [
        "Programming",
        "Design",
        "Vue",
        "Vuetify",
        "green",
        "purple",
        "indigo",
        "cyan",
        "teal",
        "orange",
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
      ],
    };
  },
};
</script>

<style lang="scss">
.v-select__slot > .v-select__selections {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #dddddd;
}
</style>
