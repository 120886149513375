<template>
  <v-row>
    <v-col cols="4">
      <Combobox label="SELECT CLINIC"></Combobox>
    </v-col>
    <v-col cols="4">
      <Combobox label="Select Diagnosis"></Combobox>
    </v-col>
  </v-row>
</template>

<script>
import Combobox from "../testcomp/ComboBox.vue";
export default {
  components: {
    Combobox,
  },
};
</script>

<style>
</style>
