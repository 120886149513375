<template>
  <div class="page">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">성장 기록하기</h1>
    </div>
    <div class="page-content">
      <div class="content-intro">
        <v-row>
          <v-col cols="4">
            <Character
              :characterLevel="characterLevel"
              :starLevel="starLevel"
            />
          </v-col>
          <v-col cols="8">
            <h6>성장을 기록해 주세요!</h6>
          </v-col>
        </v-row>
      </div>
      <v-row class="grow-row mt-4">
        <div class="last-record mb-1">
          최종 기록 : <span>{{ heightRecord }}</span> cm
        </div>
        <v-col cols="12">
          <v-text-field
            type="number"
            ref="height"
            :value="height"
            @input="onchangeHeight"
            label=""
            outlined
            dense
            hide-details
            onkeydown="javascript: return event.keyCode == 69 ? false : true"
            suffix="cm"
          />
        </v-col>
      </v-row>
      <v-row class="grow-row mt-4">
        <div class="last-record mb-1">
          최종 기록 : <span>{{ weightRecord }}</span> kg
        </div>
        <v-col cols="12">
          <v-text-field
            ref="weight"
            :value="weight"
            @input="onchangeWeight"
            type="number"
            label=""
            outlined
            dense
            hide-details
            onkeydown="javascript: return event.keyCode == 69 ? false : true"
            suffix="kg"
          />
        </v-col>
      </v-row>
      <v-row class="grow-row mt-9">
        <v-col cols="6">
          <DatePicker
            label="측정일"
            :date="measureDate"
            :allowedDateRule="
              (val) =>
                val <= $moment().format('YYYY-MM-DD') &&
                val >= $moment(firstInjectionDate).format('YYYY-MM-DD')
            "
            @onchange="onchangeMeasureDate"
          />
        </v-col>
        <v-col cols="6">
          <TimePickerInput
            label="측정시간 (24시간제)"
            :time="measureTime"
            @onchange="onchangeMeasureTime"
          />
        </v-col>
      </v-row>
    </div>
    <div class="bottom-btn">
      <v-btn color="primary" depressed large @click="clickSaveBtn"> 저장</v-btn>
    </div>
    <Common_Popup
      :openCloseStatus="commonPopupStatus"
      :text="commonPopupText"
      @onchangeOpenCloseStatus="onchangeOpenCloseStatus"
      @clickCloseBtn="clickCommonPopupCloseBtn"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/common/vuetify/DatePicker.vue";
import TimePickerInput from "@/components/common/vuetify/TimePickerInput.vue";
import Common_Popup from "@/components/common/popup/Common_Popup.vue";
import { getBodyInfo, getBodyInfoList } from "@/api/bodyInfo";
import requiredText from "@/components/common/growRecord/requiredText";
import store from "@/store/index";
import Character from "@/components/common/growRecord/Character.vue";
export default {
  components: {
    DatePicker,
    TimePickerInput,
    Common_Popup,
    Character,
  },
  data() {
    return {
      characterLevel: null,
      starLevel: null,
      measureDate: this.$moment().format("YYYY-MM-DD"),
      measureTime: this.$moment().format("HH:mm"),
      // time: null,
      // menu: false,

      height: null,
      weight: null,
      heightRecord: null,
      weightRecord: null,

      // 공통 팝업
      commonPopupStatus: false,
      commonPopupText: "",

      // 첫 주사시작일
      firstInjectionDate: null,
    };
  },
  mounted() {
    this.getBodyInfo();
    this.getLevelUpData();
  },
  methods: {
    clickBackBtn() {
      this.$router.push("grow");
    },
    clickSaveBtn() {
      // 필수 입력 체크
      if (this.checkRequired()) return;
      this.saveBodyInfo();
    },
    clickCommonPopupCloseBtn() {
      this.commonPopupStatus = false;
    },
    onchangeHeight(value) {
      const result = value.replace(
        /[0-9]{4}$/g,
        value.substring(0, value.length - 1)
      ); // 3자리 이상 제한
      this.$refs.height.lazyValue = result;
      this.height = result;
      if (result > 300) {
        this.$refs.height.lazyValue = ""; // 300 이상 제한
        this.height = "";
      }
    },
    onchangeWeight(value) {
      const result = value.replace(
        /[0-9]{4}$/g,
        value.substring(0, value.length - 1)
      ); // 3자리 이상 제한
      this.$refs.weight.lazyValue = result;
      this.weight = result;
      if (result > 300) {
        this.$refs.weight.lazyValue = ""; // 300키로 이상 제한
        this.weight = "";
      }
    },
    onchangeOpenCloseStatus(value) {
      this.commonPopupStatus = value;
    },
    onchangeMeasureDate(value) {
      this.measureDate = value;
    },
    onchangeMeasureTime(value) {
      this.measureTime = value;
    },
    clickBack() {},
    save() {},
    bindDataSet(bodyInfo, myInfo) {
      if (bodyInfo) {
        this.heightRecord = bodyInfo.height;
        this.weightRecord = bodyInfo.weight;
        this.height = bodyInfo.height;
        this.weight = bodyInfo.weight;
      }
      this.firstInjectionDate = myInfo.first_injection_date;
    },
    checkRequired() {
      const popupOpen = (columnName) => {
        this.commonPopupStatus = true;
        this.commonPopupText = requiredText[columnName];
        return true;
      };
      if (!this.height) return popupOpen("height");
      if (!this.weight) return popupOpen("weight");
      if (!this.measureDate) return popupOpen("measureDate");
      if (!this.measureTime) return popupOpen("measureTime");

      //  첫 주사일이 성장정보 측정일자 이후 일경우 저장막기
      const firstInjectionDateIsAfterMeasureDate = this.$moment(
        this.firstInjectionDate
      ).isAfter(this.$moment(this.measureDate), "days");
      if (firstInjectionDateIsAfterMeasureDate)
        return popupOpen("firstInjectionDateIsAfterMeasureDate");
    },
    async getBodyInfo() {
      const userIndex = this.$store.getters.getUserIndex;
      const reqData = {
        userIndex,
      };
      try {
        const [bodyInfoList] = await getBodyInfoList(reqData); // 여러개의 데이터가 내려오지만 시간순으로 가장 마지막 데이터를 읽어오면 됨.
        const myInfo = await store.dispatch("getMyInfo");
        console.log(bodyInfoList);
        this.bindDataSet(bodyInfoList, myInfo);
      } catch (error) {
        console.log(error);
      }
    },
    async getLevelUpData() {
      const [res] = await store.dispatch("getLevelPopupData");
      if (res) {
        this.characterLevel = res.user_character_level;
        this.starLevel = res.user_star_level;
      }
    },
    async saveBodyInfo() {
      const userIndex = store.getters.getUserIndex;
      const bodyInfo = {
        height: this.height,
        weight: this.weight,
        measureDatetime: `${this.measureDate} ${this.measureTime}`,
        userIndex,
      };
      try {
        const res = await store.dispatch("saveBodyInfo", bodyInfo);
        if (res) {
          console.log("저장완료");
          this.$router.push({ name: "app" });
        } else {
          alert("저장에 실패 했습니다.");
          throw new Error("저장에 실패 했습니다.");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.grow-row {
  .last-record {
    width: 100%;
    text-align: right;
    color: #555555;
    span {
      color: $secondary;
    }
  }
  .col:last-child {
    padding: 0;
  }
}
</style>