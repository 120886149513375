<template>
<div class="search-wrap">
  <v-text-field
    v-model="search"
    append-icon="mdi-magnify"
    label="Search"
    hide-details
  />
</div>

</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  watch: {
    search() {
      // console.log(this.search);
      this.$emit('searchEvent', this.search);
    }
  }
};
</script>

<style lang="scss">
.search-wrap {
  margin-bottom: 20px;
}
</style>
