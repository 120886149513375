export class MedicationDay {
  list = [
    { text: null, id: 0, disabled: false },
    { text: null, id: 1, disabled: false },
    { text: null, id: 2, disabled: false },
    { text: null, id: 3, disabled: false },
    { text: null, id: 4, disabled: false },
    { text: null, id: 5, disabled: false },
    { text: null, id: 6, disabled: false },
  ];
  /** data 값으로 일주일의 값을 넣어주면 해당 리스트 배열로 리턴함 (인자값의 length는 7개 필수)
   * ex) [1,1,1,1,1,1,-1]
   */
  setMedicationDay(data) {
    const medicationDay = new MedicationDay().list;
    if (data.length === 7) {
      return medicationDay.map((item, index) => {
        item.text = data[index] == -1 ? null : data[index];
        item.disabled = data[index] == -1 ? true : false;
        return item;
      });
    } else {
      return false;
    }
  }
}
export class MedicationEquipment {
  name = ""; // 투약기구 이름
  dailyPrescriptionDose = []; //  하루 처방 용량
  dosingCycle = []; //  투약 주기
  medicationDay = []; //  투약 요일
  // #dosingTotalDosage = [];
  //  (하루처방용량, 투약주기)
  createEquipment(name) {
    let result;
    switch (name) {
      case "aluetta":
        result = new Aluetta();
        break;
      case "easyPod":
        result = new EasyPod();
        break;
      default:
        break;
    }
    return result;
  }
  checkMedicationDay(dailyPrescriptionDose, dosingCycle) {
    // console.log(this.dosingCycle);
    const IU = this.dailyPrescriptionDose.indexOf(dailyPrescriptionDose);
    const CYCLE = this.dosingCycle.indexOf(dosingCycle);
    if (IU > -1 && CYCLE > -1) {
      return this.setMedicationDay(IU, CYCLE);
    } else {
      return null;
    }
  }
  setMedicationDay(IU, CYCLE) {
    const { list: schedule } = new MedicationDay();
    const index = IU * 2 + CYCLE;
    const medicationDay = this.medicationDay[index];

    const result = schedule.map((item, index) => {
      if (this.name === "easyPod") {
        medicationDay[index]
          ? (item.text = medicationDay[index].toFixed(2))
          : null;
      }
      if (this.name === "aluetta") {
        medicationDay[index]
          ? (item.text = medicationDay[index].toFixed(1))
          : null;
      }
      item.disabled = medicationDay[index] === null ? true : false;
      return item;
    });
    return result;
  }
}

export class Aluetta extends MedicationEquipment {
  name = "aluetta";
  dailyPrescriptionDose = [
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
  ];
  dosingCycle = [6, 7];
  medicationDay = [
    [0.3, 0.3, 0.3, 0.3, 0.4, 0.4, null], // 0, 0 [0] (규칙 IU * 2 + cycle)
    [0.3, 0.3, 0.3, 0.3, 0.3, 0.4, 0.4], // 0, 1 [1]
    [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, null], // 1, 0 [2]
    [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5], // 1, 1 [3]
    [0.6, 0.6, 0.7, 0.7, 0.7, 0.7, null], // 2, 0 [4]
    [0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.7], // 2, 1 [5]
    [0.8, 0.8, 0.8, 0.8, 0.9, 0.9, null], // 3, 0 [6]
    [0.8, 0.8, 0.8, 0.8, 0.8, 0.9, 0.9], // 3, 1 [7]
    [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, null],
    [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0],
    [1.1, 1.1, 1.2, 1.2, 1.2, 1.2, null],
    [1.1, 1.1, 1.2, 1.2, 1.2, 1.2, 1.2],
    [1.3, 1.3, 1.3, 1.3, 1.4, 1.4, null],
    [1.3, 1.3, 1.3, 1.3, 1.3, 1.4, 1.4],
    [1.5, 1.5, 1.5, 1.5, 1.5, 1.5, null],
    [1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5],
    [1.6, 1.6, 1.7, 1.7, 1.7, 1.7, null],
    [1.6, 1.6, 1.7, 1.7, 1.7, 1.7, 1.7],
    [1.8, 1.8, 1.8, 1.8, 1.9, 1.9, null],
    [1.8, 1.8, 1.8, 1.8, 1.8, 1.9, 1.9],
    [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, null],
    [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
    [2.1, 2.1, 2.2, 2.2, 2.2, 2.2, null],
    [2.1, 2.1, 2.2, 2.2, 2.2, 2.2, 2.2],
    [2.3, 2.3, 2.3, 2.3, 2.4, 2.4, null],
    [2.3, 2.3, 2.3, 2.3, 2.3, 2.4, 2.4],
    [2.5, 2.5, 2.5, 2.5, 2.5, 2.5, null],
    [2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5],
    [2.6, 2.6, 2.7, 2.7, 2.7, 2.7, null],
    [2.6, 2.6, 2.7, 2.7, 2.7, 2.7, 2.7],
    [2.8, 2.8, 2.8, 2.8, 2.9, 2.9, null],
    [2.8, 2.8, 2.8, 2.8, 2.8, 2.9, 2.9],
    [3.0, 3.0, 3.0, 3.0, 3.0, 3.0, null],
    [3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0],
    [3.1, 3.1, 3.2, 3.2, 3.2, 3.2, null],
    [3.1, 3.1, 3.2, 3.2, 3.2, 3.2, 3.2],
    [3.3, 3.3, 3.3, 3.3, 3.4, 3.4, null],
    [3.3, 3.3, 3.3, 3.3, 3.3, 3.4, 3.4],
  ];
}
export class EasyPod extends MedicationEquipment {
  name = "easyPod";
  dailyPrescriptionDose = [
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
  ];
  dosingCycle = [6, 7];
  medicationDay = [
    [0.33, 0.33, 0.33, 0.33, 0.33, 0.33, null],
    [0.33, 0.33, 0.33, 0.33, 0.33, 0.33, 0.33],
    [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, null],
    [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    [0.67, 0.67, 0.67, 0.67, 0.67, 0.67, null],
    [0.67, 0.67, 0.67, 0.67, 0.67, 0.67, 0.67],
    [0.83, 0.83, 0.83, 0.83, 0.83, 0.83, null],
    [0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83],
    [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, null],
    [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0],
    [1.17, 1.17, 1.17, 1.17, 1.17, 1.17, null],
    [1.17, 1.17, 1.17, 1.17, 1.17, 1.17, 1.17],
    [1.33, 1.33, 1.33, 1.33, 1.33, 1.33, null],
    [1.33, 1.33, 1.33, 1.33, 1.33, 1.33, 1.33],
    [1.5, 1.5, 1.5, 1.5, 1.5, 1.5, null],
    [1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5],
    [1.67, 1.67, 1.67, 1.67, 1.67, 1.67, null],
    [1.67, 1.67, 1.67, 1.67, 1.67, 1.67, 1.67],
    [1.83, 1.83, 1.83, 1.83, 1.83, 1.83, null],
    [1.83, 1.83, 1.83, 1.83, 1.83, 1.83, 1.83],
    [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, null],
    [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
    [2.17, 2.17, 2.17, 2.17, 2.17, 2.17, null],
    [2.17, 2.17, 2.17, 2.17, 2.17, 2.17, 2.17],
    [2.33, 2.33, 2.33, 2.33, 2.33, 2.33, null],
    [2.33, 2.33, 2.33, 2.33, 2.33, 2.33, 2.33],
    [2.5, 2.5, 2.5, 2.5, 2.5, 2.5, null],
    [2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5],
    [2.67, 2.67, 2.67, 2.67, 2.67, 2.67, null],
    [2.67, 2.67, 2.67, 2.67, 2.67, 2.67, 2.67],
    [2.83, 2.83, 2.83, 2.83, 2.83, 2.83, null],
    [2.83, 2.83, 2.83, 2.83, 2.83, 2.83, 2.83],
    [3.0, 3.0, 3.0, 3.0, 3.0, 3.0, null],
    [3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0],
    [3.17, 3.17, 3.17, 3.17, 3.17, 3.17, null],
    [3.17, 3.17, 3.17, 3.17, 3.17, 3.17, 3.17],
    [3.33, 3.33, 3.33, 3.33, 3.33, 3.33, null],
    [3.33, 3.33, 3.33, 3.33, 3.33, 3.33, 3.33],
  ];
}
