<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="grow-list"
    scrollable
  >
    <div class="top-header">
      <button class="header-btn btn-back" @click="Close()">
        <v-icon large> mdi-close </v-icon>
      </button>
      <h1 class="dialog-title">처방 기록 리스트</h1>
    </div>
    <div class="dialog-content">
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">투약기구</th>
              <th class="text-center">처방용량</th>
              <th class="text-center">처방일수</th>
              <th class="text-center">처방정보 입력일자</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in prescriptionList" :key="index">
              <td>{{ changeInjectionToolName(item.injection_tool) }}</td>
              <td>{{ item.daily_injection_dose_label }}</td>
              <td>{{ item.number_of_injections_per_week_label }}</td>
              <td>{{ item.prescription_date_label }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-dialog>
</template>

<script>
import { getPrescriptionList } from "@/api/prescription";
export default {
  data() {
    return {
      dialog: false,
      prescriptionList: [
        {
          key: 0,
          device: "알루에타",
          capacity: "2.5 IU",
          numberOfDays: "주6회",
          prescriptionDate: "2022년 07월 11일",
        },
        {
          key: 1,
          device: "알루에타",
          capacity: "2.5 IU",
          numberOfDays: "주6회",
          prescriptionDate: "2022년 07월 11일",
        },
        {
          key: 2,
          device: "알루에타",
          capacity: "2.5 IU",
          numberOfDays: "주6회",
          prescriptionDate: "2022년 07월 11일",
        },
      ],
    };
  },
  methods: {
    Open() {
      this.dialog = true;
      this.getListData();
    },
    Close() {
      this.dialog = false;
    },
    async getListData() {
      const variables = {
        userIndex: this.$store.getters.getUserIndex,
      };
      const res = await getPrescriptionList(variables);
      console.log(res);
      this.prescriptionList = res;
    },
    changeInjectionToolName(name) {
      let result;
      switch (name) {
        case "easyPod":
          result = "이지포드";
          break;
        case "aluetta":
          result = "알루에타";
          break;
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.grow-list {
  background: #ffffff;
  padding-top: 54px;
  .top-header {
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    position: fixed;
    width: 100%;
    background: #ffffff;
    top: 0;
    z-index: 200;
    .dialog-title {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      margin: 0;
    }
    .header-btn {
      position: absolute;
      top: 10px;
      left: 10px;
      float: left;
      .v-icon {
        color: $primary;
      }
    }
  }
  .dialog-content {
    padding: 20px;
    .v-data-table {
      height: 100%;
    }
    .v-data-table__wrapper {
      height: 100%;
      th {
        background: #f8f8f8 !important;
        color: #000000 !important;
        font-weight: 700;
        height: 40px;
        padding: 0 5px;
      }
      td {
        text-align: center;
        padding: 0 5px;
      }
    }
  }
}
</style>