// 8.25
import moment from "moment";

export class Calendar {
  list = [];
  dataSet = {
    day: "",
    isInjectionStatus: "",
    isReplaceCartridge: "",
    isBreakDay: false,
    injected_body_part: "",
    injected_body_part_2nd: "",
    injection_dose: "",
    injection_dose_2nd: "", // -1
    injection_review: "",
    injection_emoticon: "",
    injection_index: "",
  };

  /** @param {date} date - 날짜 입력 하면 달력 배열이 리턴됨 */
  setCalendarList = (date) => {
    try {
      if (!moment(date).isValid())
        throw new Error("date 형식이 올바르지 않습니다.");
      const endOfMonthDay = moment(date)
        .endOf("month")
        .format("DD"); // ex) 31, 30
      const month = moment(date).format("MM"); // ex) 12
      const year = Number(moment(date).format("YYYY")); // ex) 2022
      const calendar = new Calendar();
      const { list, dataSet } = calendar;
      const days = ["월", "화", "수", "목", "금", "토", "일"];
      const daysEn = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      for (let i = 1; i <= endOfMonthDay; i++) {
        const day = String(i).length === 1 ? 0 + String(i) : i; // 이유: moment에 입력할때 2022-08-9 로 입력하게 되면 warning 문구 발생
        const dayOfWeek =
          daysEn[days.indexOf(moment(`${year}-${month}-${day}`).format("ddd"))];
        list.push({
          year,
          month,
          day: i,
          dayOfWeek,
          isInjectionStatus: "default",
          isReplaceCartridge: false,
          isBreakDay: false,
        });
      }
      return list;
    } catch (error) {
      console.log(error);
    }
  };
  /**
   *  @param {Array} calendarList - 달력 리스트
   *  @param {Array} injectionLogList - 주사 이력 리스트
   *  @param {Object} lastPrescription - 처방 정보
   */
  updateCalendarList = (calendarList, injectionLogList, lastPrescription) => {
    // console.log(calendarList, injectionLogList, lastPrescription);
    let result = calendarList;
    // (주사완료, 부분주사, 카트리지 교체일 반영)
    injectionLogList.forEach((item) => {
      const date = `${item.year}-${item.month}-${item.day}`;
      result = calendarList.map((element) => {
        const calendarDate = `${element.year}-${Number(element.month)}-${element.day
          }`;
        const isEqual = date === calendarDate;
        if (isEqual) {
          const is_injected = item.is_injected === "Y" ? true : false;
          const injected_status = item.injected_status;
          const is_replaced_cartridge =
            item.is_replaced_cartridge === "Y" ? true : false;
          const injected_body_part = item.injected_body_part;
          const injected_body_part_2nd = item.injected_body_part_2nd;
          const injection_dose = item.injection_dose;
          const injection_dose_2nd = item.injection_dose_2nd;
          const injection_review = item.injection_review;
          const injection_emoticon = item.injection_emoticon;
          const injection_index = item.injection_index;

          if (is_injected && injected_status)
            element.isInjectionStatus = injected_status;
          if (is_injected && is_replaced_cartridge)
            element.isReplaceCartridge = true;
          if (is_injected && injected_body_part)
            element.injected_body_part = injected_body_part;
          if (is_injected && injected_body_part_2nd)
            element.injected_body_part_2nd = injected_body_part_2nd;
          if (is_injected && injection_dose)
            element.injection_dose = injection_dose;
          if (is_injected && injection_dose_2nd)
            element.injection_dose_2nd = injection_dose_2nd;
          if (is_injected && injection_review)
            element.injection_review = injection_review;
          if (is_injected && injection_review)
            element.injection_review = injection_review;
          if (is_injected && injection_emoticon)
            element.injection_emoticon = injection_emoticon;
          if (injection_index) element.injection_index = injection_index; // 투약하지않음 저장할경우
        }
        return element;
      });
    });
    // (주사놓침, 휴약일 반영)
    const daysEn = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    // 휴약일이 있는 경우
    // console.log(result);
    result.filter((item) => {
      const day =
        String(item.day).length === 1 ? 0 + String(item.day) : item.day;
      const injectionDate = `${item.year}-${item.month}-${day}`;
      const prescription = this.findLastPrescription(
        lastPrescription,
        injectionDate
      );
      if (prescription) {
        const weekDay = [
          prescription.mon,
          prescription.tue,
          prescription.wed,
          prescription.thu,
          prescription.fri,
          prescription.sat,
          prescription.sun,
        ];
        const breakDayArray = weekDay.map((item, index) => item == -1 ? index : '').filter(item => item) // mon 11.29 ['mon', 'tue'] 처럼 휴약일 여러개 있을경우 있음
        const breakDay = breakDayArray.map(item => daysEn[item]);
        if (breakDay) {
        }
        if (breakDay.includes(item.dayOfWeek)) item.isBreakDay = true;
      }
    });

    return result;
  };
  /**
   * @param {Array} calendarList - 달력리스트
   * @param {string} date - 날짜 ex) 2022-08-29
   * */
  searchInjectionData = (calendarList, date) => {
    return calendarList.find((item) => {
      const day =
        String(item.day).length === 1 ? 0 + String(item.day) : item.day; // 이유: moment에 입력할때 2022-08-9 로 입력하게 되면 warning 문구 발생
      const calendarDate = `${item.year}-${item.month}-${day}`;
      const isEqual = date === calendarDate;
      const isInjectionStatus =
        item.isInjectionStatus === "complete" ||
        item.isInjectionStatus === "incomplete";
      const isBreakDay = item.isBreakDay;
      const isNonInjectionStatus = item.injection_index; // 투약하지않음 저장할경우
      if (isEqual && (isInjectionStatus || isBreakDay)) {
        return item;
      }
      // 투약하지않음 저장할경우
      if (isEqual && isNonInjectionStatus) {
        return item;
      }
    });

    return;
  };
  /**
   * @description 마지막 처방정보 찾기
   * @returns {object} 마지막 처방정보
   * @param {Array} data - lastPrescription 전체 처방정보
   * @param {String} injectionDate - 선택한 날짜 (ex. 2022-09-02)
   * */
  findLastPrescription(lastPrescription, injectionDate) {
    if (lastPrescription.length < 1) return null;
    // console.log(lastPrescription)
    const firstPrescriptionDate = lastPrescription.reduce(
      (previous, current) => {
        const previousIsBeforeCurrent = moment(
          previous.prescription_date
        ).isSameOrBefore(moment(current.prescription_date))
          ? previous
          : current;
        return previousIsBeforeCurrent;
      }
    );
    // console.log("firstPrescriptionDate: ", firstPrescriptionDate);
    // step1. 첫처방정보 입력일자가 선택 날짜보다 이후 일경우 -> 저장 불가하게 되어야 함.
    const isAfterFirstPrescriptionDate = moment(
      firstPrescriptionDate.prescription_date
    ).isAfter(moment(injectionDate));
    // console.log(isAfterFirstPrescriptionDate);
    if (isAfterFirstPrescriptionDate) {
      this.isBreakDay = true;
      return;
    }
    // step2. 선택날짜의 이전날짜이면서 (filter, isSameOrBefore)
    const isBeforePrescriptionDate = lastPrescription.filter((item) => {
      moment(item.prescription_date).isSameOrBefore(moment(injectionDate));
      return item;
    });
    // console.log("isBeforePrescriptionDate: ", isBeforePrescriptionDate);

    // step3. 만약 같은날에 여러 처방일이 있을경우 prescription_index 값이 높은 데이터로 지정
    const distinctPrescription = isBeforePrescriptionDate.reduce(
      (acc, currentValue) => {
        const index = acc.findIndex(
          (item) => item.prescription_date === currentValue.prescription_date
        );
        if (index > -1) {
          return acc[index].prescription_index > currentValue.prescription_index
            ? acc
            : (acc[index] = currentValue);
        } else {
          return [...acc, currentValue];
        }
      },
      []
    );
    // console.log("distinctPrescription: ", distinctPrescription);
    // step4. 제일 가까운 날짜 (reduce) 찾기
    const theNearestDate = distinctPrescription.reduce((previous, current) => {
      const previousDiff = moment(previous.prescription_date).diff(
        moment(injectionDate),
        "days"
      );
      const currentDiff = moment(current.prescription_date).diff(
        moment(injectionDate),
        "days"
      );
      return Math.abs(previousDiff) < Math.abs(currentDiff)
        ? previous
        : current;
    });
    // console.log(
    //   "theNearestDate: ",
    //   theNearestDate,
    //   "injectedDate: ",
    //   injectionDate
    // );

    return theNearestDate;
  }
}
