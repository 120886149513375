<template>
  <v-dialog v-model="dialog" content-class="popup" persistent>
    <div class="popup-content">
      <p>
        처음 이용하시나요? <br />
        나의 정보를 입력하고 서비스를 이용해 보세요!
      </p>
      <v-btn
        color="secondary"
        class="w-100"
        depressed
        @click="PageTo('myinfo')"
      >
        나의 정보 입력하기
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
    PageTo(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.common {
}
</style>