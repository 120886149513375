<template>
  <div class="page">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">월별 성장 미션</h1>
    </div>
    <div class="page-content">
      <div class="content-intro">
        <v-row>
          <v-col cols="4">
            <Character
              :characterLevel="characterLevel"
              :starLevel="starLevel"
            />
          </v-col>
          <v-col cols="8">
            <!-- <h6>멋져요! <br />이번 달 투약을 참 잘했어요!</h6> -->
            <h6>{{ computedText }}</h6>
          </v-col>
        </v-row>
      </div>
      <div class="grow-summary mt-4">
        <div class="index-btn-wrap">
          <v-btn icon color="secondary" class="btn-index" @click="openIndex">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </div>
        <div class="summary-title">
          <b class="c-prm">{{ targetYear }}년 {{ targetMonth }}월</b> 성장 요약
        </div>
        <div class="grey-card mt-4">
          <div class="obtained-sticker">
            <div class="sticker">
              <img
                :src="
                  require(`@/assets/images/sticker/sticker_compliance_${
                    compliance ? 'active' : 'default'
                  }.png`)
                "
                alt=""
              />
              <p>
                치료 순응도 <br />
                80% 이상
              </p>
            </div>
            <div class="sticker">
              <img
                :src="
                  require(`@/assets/images/sticker/sticker_bravely_${
                    bravely ? 'active' : 'default'
                  }.png`)
                "
                alt=""
              />
              <p>
                씩씩하게<br />
                주사 맞은 날
              </p>
            </div>
            <div class="sticker">
              <img
                :src="
                  require(`@/assets/images/sticker/sticker_noDuplicate_${
                    noDuplicate ? 'active' : 'default'
                  }.png`)
                "
                alt=""
              />
              <p>
                중복된 투여<br />
                부위 없음
              </p>
            </div>
          </div>
        </div>

        <h2 class="page-sub-title mt-8">이만큼 주사 투여 했어요.</h2>
        <ul>
          <li>
            예정된 투약일 <span>{{ expectedDays }}일</span> 중
            <span>{{ injectedDays }}일</span> 투약 완료!
          </li>
          <li>
            치료 순응도 <span>{{ injectedPercent }}%</span>
          </li>
        </ul>
        <h2 class="page-sub-title mt-6">이렇게 잘 투약 했어요.</h2>
        <ul>
          <li>
            중복된 부위에 투약한 날
            <span>{{ countInjectedOverlapBodyPart }}일</span>
          </li>
          <li>성장 캐릭터</li>
        </ul>

        <div class="emotions mt-6">
          <div class="emotion">
            <img src="@/assets/images/emotion/best.png" alt="" />
            <p>{{ countEmoticonBest }}개</p>
          </div>
          <div class="emotion">
            <img src="@/assets/images/emotion/good.png" alt="" />
            <p>{{ countEmoticonGood }}개</p>
          </div>
          <div class="emotion">
            <img src="@/assets/images/emotion/notbad.png" alt="" />
            <p>{{ countEmoticonNotbad }}개</p>
          </div>
          <div class="emotion">
            <img src="@/assets/images/emotion/bad.png" alt="" />
            <p>{{ countEmoticonBad }}개</p>
          </div>
        </div>
      </div>
    </div>
    <IndexStickerPopup ref="IndexStickerPopup" />
  </div>
</template>

<script>
import IndexStickerPopup from "@/components/common/popup/IndexSticker_Popup.vue";
import Character from "@/components/common/growRecord/Character.vue";
import { getMission } from "@/api/injectionMission";
export default {
  components: {
    IndexStickerPopup,
    Character,
  },
  data() {
    return {
      characterLevel: null,
      starLevel: null,

      targetYear: null,
      targetMonth: null,

      compliance: false,
      bravely: false,
      noDuplicate: false,

      expectedDays: null,
      injectedDays: null,
      injectedPercent: null,
      countInjectedOverlapBodyPart: null,
      countEmoticonBest: null,
      countEmoticonGood: null,
      countEmoticonNotbad: null,
      countEmoticonBad: null,
    };
  },
  computed: {
    computedText() {
      let result = "";
      if (this.compliance && this.bravely && this.noDuplicate) {
        result = "멋져요! 이번 달 투약을 참 잘했네요!";
      }
      if (!this.compliance) {
        result = "다음 달에는 스티커를 모두 받을 수 있도록 노력해 보아요!";
      }
      if (!this.bravely) {
        result = "힘들겠지만, 조금만 더 씩씩해져 봐요!";
      }
      if (!this.noDuplicate) {
        result = "동일 부위에 주사하지 않도록 주사 부위 교체에 신경 써주세요!";
      }
      if (
        2 <=
        [this.compliance, this.bravely, this.noDuplicate].filter(
          (item) => !item
        ).length
      ) {
        result = "다음 달에는 스티커를 모두 받을 수 있도록 노력해 보아요!";
      }
      return result;
    },
  },
  mounted() {
    this.getLevelUpData();
    this.getMissionData();
  },
  methods: {
    clickBackBtn() {
      this.$router.push("mission");
    },
    openIndex() {
      this.$refs.IndexStickerPopup.Open();
    },
    bindCalendarData(data) {
      this.targetYear = data.target_year;
      this.targetMonth = data.target_month;

      this.compliance = data.is_80_per_over === "Y" ? true : false;
      this.bravely = data.is_5_pcs_less === "Y" ? true : false;
      this.noDuplicate = data.is_no_overlap === "Y" ? true : false;

      this.expectedDays = data.expected_days;
      this.injectedDays = data.injected_days;
      this.injectedPercent = data.injected_percent;
      this.countInjectedOverlapBodyPart = data.count_injected_overlap_body_part;
      this.countEmoticonBest = data.count_emoticon_best;
      this.countEmoticonGood = data.count_emoticon_good;
      this.countEmoticonNotbad = data.count_emoticon_notbad;
      this.countEmoticonBad = data.count_emoticon_bad;
    },
    async getLevelUpData() {
      const [res] = await this.$store.dispatch("getLevelPopupData");
      if (res) {
        this.characterLevel = res.user_character_level;
        this.starLevel = res.user_star_level;
      }
    },
    async getMissionData() {
      const userIndex = this.$store.getters.getUserIndex;
      const variable = {
        userIndex,
        targetYear: this.$route.params.targetYear,
        targetMonth: this.$route.params.targetMonth,
      };
      const res = await getMission(variable);
      console.log(res);
      if (res) {
        this.bindCalendarData(res[0]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.grow-summary {
  .summary-title {
    font-size: 18px;
    color: #555555;
  }
  .obtained-sticker {
    display: table;
    width: 100%;
    .sticker {
      display: table-cell;
      text-align: center;
      img {
        width: 72px;
      }
      p {
        color: #555555;
        line-height: 14px;
        font-size: 11px;
        margin-bottom: 5px;
      }
    }
  }
  ul {
    padding-left: 40px;
    li {
      color: #555555;
      line-height: 24px;
      span {
        color: $secondary;
      }
    }
  }
  .emotions {
    display: table;
    width: 220px;
    margin: 0 auto;
    .emotion {
      display: table-cell;
      text-align: center;
      img {
        width: 30px;
      }
      p {
        color: $secondary;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
</style>