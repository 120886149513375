<template>
  <v-date-picker
    v-model="computedDate"
    :events="functionEvents"
    @click:date="clickDate"
    :picker-date.sync="computedPickerDate"
    event-color="green lighten-1"
    class="calendar"
    full-width
    no-title
    :weekday-format="getDay"
    :month-format="getMonth"
    :title-date-format="getMonth"
    :header-date-format="getHeaderTitleMonth"
    :locale="locale"
    :allowed-dates="allowedDateRule"
    :min="min"
    :max="max"
  >
  </v-date-picker>
</template>
<script>
import moment from "moment";
export default {
  props: {
    // v-date-picker의 value 값은 String으로만 가능하다. moment를 사용한다면 상위 컴포넌트에서 moment().format('YYYY-MM-DD') 로 string으로 변경하여 prop을 전달해야한다.
    date: {
      type: String,
      default: null,
    },
    functionEvents: {
      type: Function,
      default: () => {},
    },
    pickerDate: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    allowedDateRule: {
      type: Function,
      default: () => true,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    computedDate: {
      get() {
        return this.date;
      },
      set(newValue) {
        return this.$emit("onchange", newValue);
      },
    },
    computedPickerDate: {
      get() {
        return this.pickerDate;
      },
      set(newValue) {
        return this.$emit("onchangePickerDate", newValue);
      },
    },
  },
  methods: {
    clickOkBtn(value) {
      this.$refs.menu.save(value);
    },
    clickDate(value) {
      this.$emit("clickDate", value);
    },
    getDay(date) {
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getMonth(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];

      let i = new Date(date).getMonth(date);
      return monthName[i];
    },
    getHeaderTitleMonth(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];
      let i = new Date(date).getMonth(date);
      const year = new Date(date).getFullYear(date);
      const month = monthName[i];
      return `${year}년 ${month}`;
    },
  },
};
</script>

<style>
</style>
