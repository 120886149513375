<template>
  <div class="table-top-btns">
    <v-btn large outlined class="ma-2" color="primary"> Delete </v-btn>
    <router-link to="patientRegist">
      <v-btn large class="ma-2" depressed color="primary">
        New <v-icon> mdi-plus </v-icon>
      </v-btn>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.table-top-btns {
  margin-bottom: 20px;
  text-align: right;
}
</style>
