export const DataTable = [
  {
    text: "Clinic",
    value: "clinic",
    sortable: false,
  },
  { text: "MRN", value: "mrn", sortable: false },
  { text: "Last Name", value: "lname", sortable: false },
  { text: "First Name", value: "fname", sortable: false },
  { text: "Gender", value: "gender", sortable: false },
  { text: "Age", value: "age", sortable: false },
  { text: "Diagnosis", value: "diagnosis" },
  { text: "Care plan", value: "careplan", sortable: false},
  { text: "Measure", value: "measure" },
  { text: "Ratio", value: "mratio" },
  { text: "Care time", value: "caretime" },
  { text: "Total alert", value: "totalAlert" },
  { text: "Medical", value: "medical" },
  { text: "Remained", value: "remained" },
];
