<template>
  <div>
    <chart-card title="Spline Area">
      <template slot="chart">
        <div id="basicArea-chart" style="min-height: 365px">
          <apexchart
            type="area"
            height="350"
            :options="splineArea.chartOptions"
            :series="splineArea.series"
          />
        </div>
      </template>
    </chart-card>
  </div>
</template>

<script>
import ChartCard from "../../components/card/ChartCard";
import {
  apexDemo,
  basicArea,
  splineArea,
  dateTimeXaxis,
  negativeArea,
  stackedArea,
  nullAreaChart,
} from "../../data/apexChart";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Apex- Area Charts",
  },
  components: {
    ChartCard,
  },
  data() {
    return {
      apexDemo,
      basicArea,
      splineArea,
      dateTimeXaxis,
      negativeArea,
      stackedArea,
      nullAreaChart,
    };
  },
};
</script>

<style>
</style>
