export default function (complianceValue) {
  const value = Number(complianceValue);
  if (value == 100) {
    return `대단해요~! <img src="https://ssgk.co.kr/test/img/thumbup.png"/><br/> 싸이젠 주사를 아주 잘 하고 있습니다. 매일 매일 파이팅!!`;
  } else if (80 <= value) {
    return `주사를 잘 하고 있어요. <img src="https://ssgk.co.kr/test/img/cheerup.png"/><br/>  우리 조금 더 노력해서 100% 주사하도록 해 봐요. 으쌰 으쌰!`;
  } else if (50 < value && value < 80) {
    return `조금 더 관심을 가지고 매일 싸이젠을 주사해 주세요. <img src="https://ssgk.co.kr/test/img/concern.png"/><br/>할 수 있어요~ 화이팅!!`;
  } else if (value <= 50) {
    return `싸이젠 주사를 꾸준히 맞는 것은 매우 중요합니다. <img src="https://ssgk.co.kr/test/img/warning.png"/><br/>매일 주사하는 것을 꼭! 기억해 주세요.`;
  }
}
