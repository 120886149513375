<template>
  <div class="page">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">나의 성장 기록</h1>
    </div>
    <div class="page-content">
      <div class="content-intro">
        <v-row>
          <v-col cols="4">
            <Character
              :characterLevel="characterLevel"
              :starLevel="starLevel"
            />
          </v-col>
          <v-col cols="8">
            <h6>
              <!-- 얼마나 성장했는지 확인해 볼까요? <br />
              키와 몸무게를 입력해 주세요. -->
              {{ computedText }}
            </h6>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          class="w-100"
          depressed
          large
          @click="PageTo('grow_detail')"
        >
          <v-icon small>mdi-pencil</v-icon>
          성장 기록하기
        </v-btn>
      </div>
      <div class="graph-area mt-6 mb-8">
        <div class="graph-top">
          <v-btn-toggle
            v-model="switchHW"
            @change="onchangeSwitchHW"
            rounded
            dense
          >
            <v-btn> 키 </v-btn>
            <v-btn> 몸무게 </v-btn>
          </v-btn-toggle>
          <div class="grow-num-wrap">
            <div class="grow-num">
              <v-icon small>mdi-plus</v-icon>
              {{ computedGrowNum }}
              <span>{{ computedUnit }}</span>
            </div>
            <p>{{ startedMeasureDay }}</p>
          </div>
        </div>
        <div class="chart-wrap">
          <apexchart
            type="line"
            height="350"
            :options="chartOptions"
            :series="series"
          />
          <div class="unit c-prm">{{ computedUnit }}</div>
        </div>

        <div class="graph-bottom">
          <v-btn-toggle
            v-model="growPeriod"
            @change="onchangeGrowPeriod"
            rounded
            dense
          >
            <v-btn> 1년 </v-btn>
            <v-btn> 3년 </v-btn>
            <v-btn> 모두 </v-btn>
          </v-btn-toggle>
          <div class="right-btn-wrap">
            <v-btn color="secondary" @click="clickGrowList" small depressed
              >리스트 보기</v-btn
            >
          </div>
        </div>
      </div>
    </div>

    <GrowListDialog ref="GrowListDialog" />
  </div>
</template>

<script>
import GrowListDialog from "@/components/common/dialog/GrowList_Dialog.vue";
import { getBodyInfo, getBodyInfoList } from "@/api/bodyInfo";
import { chartOptions } from "@/components/common/growRecord/chartOptions";
import Character from "@/components/common/growRecord/Character.vue";
export default {
  components: {
    GrowListDialog,
    Character,
  },
  data() {
    return {
      characterLevel: null,
      starLevel: null,
      // TODO radio btn 처럼 한개 선택 필수
      switchHW: 0,
      // TODO radio btn 처럼 한개 선택 필수
      growPeriod: 0,
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions,
      // view
      increaseHeight: null,
      increaseWeight: null,
      startedMeasureDay: null,
      bodyInfoList: [],
    };
  },
  computed: {
    computedGrowNum() {
      return this.switchHW == 0 ? this.increaseHeight : this.increaseWeight;
    },
    computedUnit() {
      return this.switchHW == 0 ? "cm" : "kg";
    },
    computedText() {
      if (this.bodyInfoList.length > 0) {
        const firstMeasureDate = this.bodyInfoList.at(-1).measure_datetime;
        const beforeThreeMonthDate = this.$moment().subtract(3, "month");
        const isThreeMonthAfter = this.$moment(beforeThreeMonthDate).isBefore(
          this.$moment(firstMeasureDate)
        );
        if (isThreeMonthAfter) {
          return `얼마나 성장했는지 그래프로 확인해 보세요.`;
        } else {
          return `얼마나 성장했는지 확인해 볼까요? 키와 몸무게를 입력해 주세요.`;
        }
        // console.log(beforeThreeMonthDate, firstMeasureDate, isThreeMonthAfter);
      }
    },
  },
  mounted() {
    this.getBodyInfoData();
    this.getBodyInfoListData();
    this.getLevelUpData();
  },
  methods: {
    /** chart data update */
    updateChart() {
      let bodyInfoList = [...this.bodyInfoList];
      if (this.switchHW > -1) {
        if (this.switchHW == 0) {
          bodyInfoList = bodyInfoList.filter((item) => item.height);
        } else if (this.switchHW == 1) {
          bodyInfoList = bodyInfoList.filter((item) => item.weight);
        }
      }
      const updateName = this.bindBodyInfoList(bodyInfoList)
        .map((item) => {
          return item.name;
        })
        .reverse();
      const updateData = this.bindBodyInfoList(bodyInfoList)
        .map((item) => {
          if (this.switchHW > -1) {
            if (this.switchHW == 0) return item.height;
            if (this.switchHW == 1) return item.weight;
          } else {
            return [];
          }
        })
        .reverse();
      // name 업데이트
      this.chartOptions = {
        xaxis: {
          categories: updateName,
        },
      };
      // data 업데이트
      if (this.switchHW == 1) {
        this.series = [
          {
            name: "몸무게",
            data: updateData,
          },
        ];
      } else {
        this.series = [
          {
            name: "키",
            data: updateData,
          },
        ];
      }
    },
    onchangeGrowPeriod(value) {
      this.getBodyInfoListData();
    },
    onchangeSwitchHW(value) {
      this.updateChart();
    },
    clickBackBtn() {
      this.$router.push("app");
    },
    PageTo(url) {
      this.$router.push(url);
    },
    clickGrowList() {
      this.$refs.GrowListDialog.Open();
    },
    bindBodyInfoList(bodyInfoList) {
      let result = bodyInfoList;
      /**
       * @deprecated 날짜조회 서버에서 처리
       * @param {number} period - 몇년 전까지인지 (ex. 3)
       * @param {Array} array - 데이터 리스트 measure_datetime 컬럼 필요
       * */
      // const divideBodyInfo = (period, array) => {
      //   const isPast = this.$moment().subtract(period, "year");
      //   const result = array.filter((item) => {
      //     return this.$moment(item.measure_datetime).isAfter(isPast);
      //   });
      //   return result;
      // };
      // switch (this.growPeriod) {
      //   case 0:
      //     result = divideBodyInfo(1, bodyInfoList);
      //     break;
      //   case 1:
      //     result = divideBodyInfo(3, bodyInfoList);
      //     break;
      //   case 2:
      //     break;
      //   default:
      //     break;
      // }
      return result.map((item) => {
        return {
          name: item.measure_date_label,
          height: item.height,
          weight: item.weight,
        };
      });
    },
    bindDataSet(bodyInfo) {
      this.increaseHeight = bodyInfo.increaseHeight;
      this.increaseWeight = bodyInfo.increaseWeight;
      this.startedMeasureDay = bodyInfo.startedMeasureDay;
    },
    async getLevelUpData() {
      const [res] = await this.$store.dispatch("getLevelPopupData");
      if (res) {
        this.characterLevel = res.user_character_level;
        this.starLevel = res.user_star_level;
      }
    },
    async getBodyInfoListData() {
      const userIndex = this.$store.getters.getUserIndex;
      const list = ["1yr", "3yr", "all"];
      const listRange = list[this.growPeriod] || "all";
      const reqData = {
        userIndex,
        listRange,
      };
      let bodyInfoList = await getBodyInfoList(reqData);

      this.bodyInfoList = bodyInfoList;

      this.updateChart();
    },
    async getBodyInfoData() {
      const userIndex = this.$store.getters.getUserIndex;
      const reqData = {
        userIndex,
      };
      const [bodyInfo] = await getBodyInfo(reqData);
      console.log(bodyInfo);
      this.bindDataSet(bodyInfo);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.graph-area {
  .graph-top {
    display: flex;
    align-items: top;
    .grow-num-wrap {
      display: inline-block;
      width: calc(100% - 110px);
      text-align: right;
      .grow-num {
        font-size: 24px;
        font-weight: 700;
        color: $primary;
        .v-icon {
          color: $primary;
          margin-right: -5px;
        }
        span {
          font-size: 12px;
          font-weight: 400;
        }
      }
      p {
        font-size: 10px;
        color: #949494;
        margin-bottom: 0;
        margin-top: -5px;
      }
    }
  }
  .chart-wrap {
    position: relative;
    width: calc(100% + 20px);
    margin: 0 0 0 -20px;
    .unit {
      position: absolute;
      bottom: 3px;
      left: 10px;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .graph-bottom {
    .right-btn-wrap {
      display: inline-block;
      width: calc(100% - 150px);
      text-align: right;
    }
  }
  .v-item-group.v-btn-toggle {
    .v-btn {
      background: #ffffff;
      color: $primary;
      border-color: $primary !important;
      font-size: 14px;
      height: 28px;
      &.v-item--active {
        background: $primary !important;
        color: #ffffff;
      }
      &::before {
        opacity: 0;
      }
    }
  }
}
</style>