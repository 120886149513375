<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDate"
        :label="label"
        outlined
        dense
        append-icon="mdi-calendar"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="computedDate"
      no-title
      scrollable
      @input="clickOkBtn"
      :allowed-dates="allowedDateRule"
      :locale="locale"
      :weekday-format="getDay"
      :month-format="getMonth"
      :title-date-format="getMonth"
      :header-date-format="getHeaderTitleMonth"
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";
export default {
  props: {
    label: {
      type: String,
      default: "Date",
    },
    // v-date-picker의 value 값은 String으로만 가능하다. moment를 사용한다면 상위 컴포넌트에서 moment().format('YYYY-MM-DD') 로 string으로 변경하여 prop을 전달해야한다.
    date: {
      type: String,
      default: null,
    },
    allowedDateRule: {
      type: Function,
      default: () => true,
    },
    locale: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    computedDate: {
      get() {
        return this.date;
      },
      set(newValue) {
        return this.$emit("onchange", newValue);
      },
    },
  },
  methods: {
    clickOkBtn(value) {
      this.$refs.menu.save(value);
    },
    getDay(date) {
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getMonth(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];

      let i = new Date(date).getMonth(date);
      return monthName[i];
    },
    getHeaderTitleMonth(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];
      let i = new Date(date).getMonth(date);
      const year = new Date(date).getFullYear(date);
      const month = monthName[i];
      return `${year}년 ${month}`;
    },
  },
};
</script>

<style lang="scss">
.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.v-input--is-readonly.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined
  .v-text-field__slot {
  position: absolute !important;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.v-menu__content {
  z-index: 150 !important;
}
</style>
