<template>
  <v-dialog v-model="dialog" content-class="popup change-criteria">
    <div class="popup-content">
      <p>
        처방정보 입력일자 {{ prescriptionDate }} 부터 Grow Diary 투약 기록
        기준이 변경됩니다.
      </p>
      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    prescriptionDate: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.change-criteria {
}
</style>