<template>
  <div>
    <!-- <v-card-title>
      Medical Alert
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title> -->
    <v-data-table :headers="headers" :items="MedicalAlert" item-key="key">
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </template>
            <span>Call</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-video</v-icon>
              </v-btn>
            </template>
            <span>Video</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>Email</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-chat-processing</v-icon>
              </v-btn>
            </template>
            <span>Chat</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "key", value: "key", sortable: false },
        { text: "Date", value: "date" },
        { text: "Indicator", value: "indicator", sortable: false },
        { text: "Value", value: "value" },
        { text: "Severity", value: "severity" },
        { text: "Alert type", value: "alertType", sortable: false },
        { text: "Message", value: "message", sortable: false },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      MedicalAlert: [
        {
          key: "0",
          date: "2021. 03. 07",
          indicator: "Blood Pressure",
          value: "210",
          severity: "Very High",
          alertType: "abnormal range",
          message: "Blood Pressure too high",
          status: "action taken",
        },
        {
          key: "1",
          date: "2021. 03. 08",
          indicator: "Blood Pressure",
          value: "210",
          severity: "Very High",
          alertType: "abnormal range",
          message: "Blood Pressure too high",
          status: "action taken",
        },
        {
          key: "2",
          date: "2021. 03. 09",
          indicator: "Blood Pressure",
          value: "210",
          severity: "Very High",
          alertType: "abnormal range",
          message: "Blood Pressure too high",
          status: "action taken",
        },
      ],
    };
  },
};
</script>

<style>
</style>
