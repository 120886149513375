<template>
  <v-dialog v-model="dialog" content-class="popup free-input">
    <div class="popup-content">
      <p>
        투약 요일별 용량(mg) 자동계산이 지원되지 않는 처방용량입니다. 하단의
        <b>[투약 요일별 용량(mg)] </b> 에 용량을 직접 입력해 주세요.
        <!-- 값을<br />
        <b class="c-red"
          >소수점 {{ injectionTool === "aluetta" ? "첫째" : "둘째" }}자리</b
        >
        까지 직접 입력해주세요. -->
      </p>
      <p class="text-center example">
        예시 : 월요일 {{ injectionTool === "aluetta" ? "1.2mg" : "1.25mg" }}
      </p>
      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    injectionTool: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.free-input {
  .example {
    font-weight: 700;
    font-size: 16px;
  }
}
</style>