export const injectionToolItems = [
  {
    value: "easyPod",
    text: "이지포드",
  },
  {
    value: "aluetta",
    text: "알루에타",
  },
];
export const numberOfInjectionsItems = [
  {
    value: "5",
    text: "주 5회",
  },
  {
    value: "6",
    text: "주 6회",
  },
  {
    value: "7",
    text: "주 7회",
  },
];

export const itemsSite = [
  {
    value: "arm1",
    text: "오른팔",
  },
  {
    value: "arm2",
    text: "왼팔",
  },
  {
    value: "abdomen1",
    text: "복부1",
  },
  {
    value: "abdomen2",
    text: "복부2",
  },
  {
    value: "abdomen3",
    text: "복부3",
  },
  {
    value: "abdomen4",
    text: "복부4",
  },
  {
    value: "butt1",
    text: "오른쪽 엉덩이",
  },
  {
    value: "butt2",
    text: "왼쪽 엉덩이",
  },
  {
    value: "thigh1",
    text: "허벅지1",
  },
  {
    value: "thigh2",
    text: "허벅지2",
  },
  {
    value: "thigh3",
    text: "허벅지3",
  },
  {
    value: "thigh4",
    text: "허벅지4",
  },
];
