<template>
  <div>
    <div class="grey-card mt-4 inject-detail" id="inject-detail">
      <div class="date">
        {{ computedSelectedDate }}
      </div>
      <div v-if="injectionSite && todayGrowth">
        <ul class="mt-2">
          <li>
            <h6>주사용량 - 주사부위</h6>
            <!-- <b>1mg - 복부 1 / 0.66mg - 허벅지 3</b> -->
            <b>{{ computedInjectionSite }}</b>
          </li>
          <li>
            <h6>
              오늘의 성장 캐릭터
              <img
                :src="
                  require(`@/assets/images/emotion/${todayGrowth.injection_emoticon}.png`)
                "
                alt=""
              />
            </h6>
            {{ todayGrowth.injection_review }}
          </li>
        </ul>
        <v-btn
          depressed
          color="primary"
          class="w-100"
          @click="clickInjectionReadBtn"
        >
          투약 정보 보기
        </v-btn>
      </div>
      <p v-if="isBreakDay">
        {{ computedBreakDay }}
        휴약일입니다.
      </p>
      <p v-if="toBeInject">
        투약 예정일입니다. <br />
        투약 후 기록해 주세요.
      </p>
      <div v-if="showInjectBtn">
        <v-btn
          depressed
          color="primary"
          class="w-100"
          @click="clickInjectionSaveBtn"
        >
          투약 기록하기
        </v-btn>
      </div>
    </div>
    <!-- <div class="grey-card mt-4 inject-detail">
      <div class="date">10.수</div>
      <p>오늘은 휴약일입니다.</p>
    </div>
    <div class="grey-card mt-4 inject-detail">
      <div class="date">03.수</div>
      <v-btn depressed color="primary" class="w-100"> 투약 기록하기 </v-btn>
    </div>
    <div class="grey-card mt-4 inject-detail">
      <div class="date">12.금</div>
      <p>
        투약 예정일입니다. <br />
        투약 후 기록해 주세요.
      </p>
      <v-btn depressed color="primary" class="w-100"> 투약 기록하기 </v-btn>
    </div>

    <div class="grey-card mt-4 inject-detail">
      <div class="date">19.금</div>
      <p>휴약일입니다.</p>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    selectedDate: {
      type: String,
      default: moment().format("D.ddd"),
    },
    injectionSite: {
      type: Object,
      default: () => ({
        dosingSite: "복부",
        dosingCapacity: "1.8",
        dosingSite2: "허벅지",
        dosingCapacity2: "3.3",
      }),
    },
    todayGrowth: {
      type: Object,
      default: () => ({
        selectedEmotion: "2",
        TodayGrowth: "오늘은 주사를 아주 잘 맞았어요. 기특해요!!",
      }),
    },
    isBreakDay: {
      type: Boolean,
      default: false,
    },
    toBeInject: {
      type: Boolean,
      default: false,
    },
    showInjectBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedSelectedDate() {
      return moment(this.selectedDate).format("D.ddd");
    },
    computedInjectionSite() {
      const siteName = [
        "arm1",
        "arm2",
        "abdomen1",
        "abdomen2",
        "abdomen3",
        "abdomen4",
        "butt1",
        "butt2",
        "thigh1",
        "thigh2",
        "thigh3",
        "thigh4",
      ];
      const siteNameKr = [
        "오른팔",
        "왼팔",
        "복부1",
        "복부2",
        "복부3",
        "복부4",
        "오른쪽 엉덩이",
        "왼쪽 엉덩이",
        "허벅지1",
        "허벅지2",
        "허벅지3",
        "허벅지4",
      ];
      if (this.injectionSite) {
        const { injected_body_part } = this.injectionSite;
        const { injection_dose } = this.injectionSite;
        const { injected_body_part_2nd } = this.injectionSite;
        const { injection_dose_2nd } = this.injectionSite;
        // 1mg - 복부 1 / 0.66mg - 허벅지 3
        if (injected_body_part_2nd) {
          return `${injection_dose}mg - ${
            siteNameKr[siteName.indexOf(injected_body_part)]
          } / ${injection_dose_2nd}mg - ${
            siteNameKr[siteName.indexOf(injected_body_part_2nd)]
          }`;
        } else {
          return `${injection_dose}mg - ${
            siteNameKr[siteName.indexOf(injected_body_part)]
          }`;
        }
      }
    },
    computedBreakDay() {
      return moment(this.selectedDate).isSame(moment(), "day") ? "오늘은" : "";
    },
  },
  mounted() {},

  methods: {
    clickInjectionSaveBtn() {
      this.$emit("clickInjectionSaveBtn");
    },
    clickInjectionReadBtn() {
      this.$emit("clickInjectionReadBtn");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>