<template>
  <div>
    <h1>Patient Register</h1>
    <v-col cols="12">
      <base-card>
        <v-card-title>Patient General</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6"><v-text-field label="First Name" /></v-col>
            <v-col cols="6"><v-text-field label="Last Name" /></v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-text-field label="E-mail" /></v-col>

            <v-col cols="6">
              <v-file-input accept="svg/*" label="Agreement"></v-file-input
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-text-field label="Device" readonly /></v-col>

            <v-col cols="4">
              <v-btn large icon class="ma-2" color="danger"
                ><v-icon>mdi-close</v-icon></v-btn
              >
              <v-btn large outlined class="ma-2" color="secondary">
                Assign
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12">
      <base-card>
        <v-card-title>Patient General</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select :items="items" label="Morning Medicine" />
            </v-col>
            <v-col cols="1">
              <v-text-field label="Pill" />
            </v-col>
            <v-col cols="5">
              <v-btn large icon class="ma-2" color="danger"
                ><v-icon>mdi-close</v-icon></v-btn
              >
              <v-btn large outlined class="ma-2" color="secondary"> Add </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12" class="bottom-buttons">
      <router-link to="patient">
        <v-btn x-large depressed color="success"> Save </v-btn>
      </router-link>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
    };
  },
};
</script>

<style lang="scss">
.bottom-buttons {
  text-align: right;
}
.v-file-input {
  flex-flow: row-reverse;
}
</style>
