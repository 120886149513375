export const chartOptions = {
  colors: ["#00A4BA", "#0074C8"],
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  // noData: {
  //   text: 'Loading...'
  // },

  xaxis: {
    // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    type: "category",
    categories: [],
    labels: {
      show: false,
    }
  },
};
