<template>
  <div>
    <v-select :items="items" v-model="model" label="Select Care Plan" />
    <v-simple-table class="simple1">
      <template v-slot:default>
        <!-- <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left">Calories</th>
        </tr>
      </thead> -->
        <tbody>
          <tr>
            <th>Progress</th>
            <td>15 days in progress (14 days left)</td>
          </tr>
          <tr>
            <th>Goal</th>
            <td>
              It is a weight management program for high blood pressure
              management. This program is for those who need to manage their
              weight and improve their nutritional habits.
            </td>
          </tr>
          <tr>
            <th>Overview</th>
            <td>
              Based on Lisa's visit, we provide nutrition/exercise care program
              for weight goals and weight management.
            </td>
          </tr>
          <tr>
            <th>
              Measurement <br />
              Indicators & Devices
            </th>
            <td>
              We analyze changes in indicators such as weight and blood pressure
              after the program starts and provide Lisa's management performance
              through report.
            </td>
          </tr>
          <tr>
            <th>Care Plan</th>
            <td>
              <li><b>Week 1 :</b> Know my health</li>
              <li><b>Week 1 :</b> Know my health</li>
              <li><b>Week 1 :</b> Know my health</li>
              <li><b>Week 1 :</b> Know my health</li>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Type</th>
            <th class="text-left">Issue Date</th>
            <th class="text-left">Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in desserts" :key="item.key">
            <td>{{ item.type }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.comment }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: 'No.12  Hypertension Care  (3 April 2021 ~  2 May 2021)',
      items: [
        "No.12  Hypertension Care  (3 April 2021 ~  2 May 2021)",
        "Bar",
        "Fizz",
        "Buzz",
      ],
      desserts: [
        {
          key: "0",
          type: "Weekly",
          date: "2021-01-01",
          comment: "Well Managed",
        },
        {
          key: "1",
          type: "Weekly",
          date: "2021-01-01",
          comment: "Well Managed",
        },
        {
          key: "2",
          type: "Weekly",
          date: "2021-01-01",
          comment: "Well Managed",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.v-data-table.theme--light.simple1 {
  th {
    text-align: right !important;
    font-size: 14px !important;
    padding: 10px !important;
    vertical-align: top;
  }
  td {
    padding: 10px !important;
  }
}
</style>
