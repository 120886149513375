<template>
  <div class="page mission">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">성장 미션</h1>
    </div>
    <div class="page-content">
      <div class="content-intro fixed">
        <v-row>
          <v-col cols="4">
            <Character
              :characterLevel="characterLevel"
              :starLevel="starLevel"
            />
          </v-col>
          <v-col cols="8">
            <h6>
              스티커를 {{ countMissionSticker || 10 }}개 더 획득하면 진화할 수
              있어요!
            </h6>
            <v-btn color="primary" class="w-100" text @click="openIndex">
              성장 미션 스티커 지급 기준 보기 >
            </v-btn>
          </v-col>
        </v-row>
        <div class="select-wrap mt-4">
          <v-select
            label=""
            :items="itemsYear"
            v-model="selectedYear"
            @input="onchangeSelectedYear"
            outlined
            dense
            hide-details
          ></v-select>
        </div>
      </div>

      <div class="month-list">
        <div
          :id="`month${item.month}`"
          :class="`list-item ${
            currentMonth === item.month.toString() &&
            item.year == $moment().format('YYYY')
              ? 'active'
              : ''
          } ${currentMonth < item.month ? 'pre' : ''}
          ${item.valid ? '' : 'no-data'}`"
          v-for="item in calendarList"
          :key="item.month"
          @click="PageTo(item)"
        >
          <div class="item-title">{{ item.month }}월</div>
          <div class="sticker">
            <img
              :src="
                require(`@/assets/images/sticker/sticker_compliance_${
                  item.compliance ? 'active' : 'default'
                }.png`)
              "
              alt=""
            />
          </div>
          <div class="sticker">
            <img
              :src="
                require(`@/assets/images/sticker/sticker_bravely_${
                  item.bravely ? 'active' : 'default'
                }.png`)
              "
              alt=""
            />
          </div>
          <div class="sticker">
            <img
              :src="
                require(`@/assets/images/sticker/sticker_noDuplicate_${
                  item.noDuplicate ? 'active' : 'default'
                }.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <IndexStickerPopup ref="IndexStickerPopup" />
  </div>
</template>

<script>
import Character from "@/components/common/growRecord/Character.vue";
import IndexStickerPopup from "@/components/common/popup/IndexSticker_Popup.vue";
import { getMissionList, getMissionYearList } from "@/api/injectionMission";
import { MonthCalendar } from "@/components/common/model/MissionCalendar";
export default {
  components: {
    Character,
    IndexStickerPopup,
  },
  data() {
    return {
      // class 선언
      MonthCalendar: new MonthCalendar(),

      characterLevel: null,
      starLevel: null,
      countMissionSticker: null,
      itemsYear: [],
      selectedYear: 0,
      currentMonth: this.$moment().format("M"),
      // list: function () {
      //   var list = [];
      //   for (var i = 1; i < 13; i++) list.push(i);
      //   return list;
      // },
      calendarList: [],
    };
  },
  async mounted() {
    this.getLevelUpData();
    this.getMissionYearListData();
    this.initCalendar();
  },
  methods: {
    clickBackBtn() {
      this.$router.push("app");
    },
    openIndex() {
      this.$refs.IndexStickerPopup.Open();
    },
    onchangeSelectedYear(value) {
      this.getMissionData();
    },
    currentMonthScroll() {
      const special = document.getElementById(`month${this.currentMonth}`);
      special.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    PageTo(item) {
      //  valid check (해당 월에 데이터가 존재하는지 확인)
      if (!item.valid) return;

      this.$router.push({
        name: "mission_detail",
        params: {
          targetYear: item.year,
          targetMonth: item.month,
        },
      });
    },
    initCalendar() {
      this.selectedYear = Number(this.$moment().format("YYYY"));
      this.calendarList = this.MonthCalendar.setMonthCalendar(
        this.selectedYear
      );
      this.onchangeSelectedYear();
      setTimeout(() => {
        this.currentMonthScroll();
      }, 500);
    },
    bindMonthCalendar(data) {
      this.calendarList = this.MonthCalendar.updateMonthCalendar(
        this.calendarList,
        data,
        this.selectedYear
      );
    },
    async getLevelUpData() {
      const [res] = await this.$store.dispatch("getLevelPopupData");
      if (res) {
        this.countMissionSticker = res.count_sticker_for_next_level;
        this.characterLevel = res.user_character_level;
        this.starLevel = res.user_star_level;
      }
    },
    async getMissionYearListData() {
      const userIndex = this.$store.getters.getUserIndex;
      const variable = {
        userIndex,
      };
      const res = await getMissionYearList(variable);
      console.log(res);
      if (res) {
        this.itemsYear = res.map((item) => {
          return { text: `${item.target_year}년`, value: item.target_year };
        });
      }
    },
    async getMissionData() {
      const userIndex = this.$store.getters.getUserIndex;
      const variable = {
        userIndex,
        targetYear: this.selectedYear,
      };
      const res = await getMissionList(variable);
      console.log(res);
      this.bindMonthCalendar(res);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.month-list {
  margin-top: 200px;
  .list-item {
    margin-bottom: 15px;
    display: table;
    width: 100%;
    &.no-data {
      .item-title {
        background: #999999;
      }
    }
    &.active {
      // outline: 3px solid $secondary;
      box-shadow: 0 0 0 3px $secondary;
      border-radius: 18px;
      .item-title {
        background: $secondary;
      }
    }
    &.pre {
      .item-title {
        background: #999999;
      }
    }
    > div {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
    .item-title {
      border-radius: 16px 0 0 16px;
      background: $primary;
      width: 50px;
      color: #ffffff;
      font-weight: 700;
      font-size: 15px;
    }
    .sticker {
      background: #f4f4f4;
      padding-top: 4px;
      &:nth-child(2) {
        text-align: right;
      }
      &:last-child {
        border-radius: 0 16px 16px 0;
        text-align: left;
      }
      img {
        width: 72px;
        display: inline-block;
      }
    }
  }
}
</style>