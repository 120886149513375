<template>
  <v-menu
    ref="menuTwo"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        label="Picker without buttons"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dates" range>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menuTwo = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menuTwo.save(dates)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    picker: new Date().toISOString().substr(0, 7),
    dates: ["2018-09-15", "2018-09-20"],
    menu: false,
    menuTwo: false,
    menu2: false,
    modal: false,
    date: null,
    dateTwo: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>

<style>
</style>
