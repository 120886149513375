<template>
  <div>
    <h1>Patient</h1>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <Searchtable @searchEvent="searchEvent"></Searchtable>
            <Combogroup></Combogroup>
            <Buttontabletop />
            <Datatable
              :showSelect="true"
              :search="search"
              :columns="columns"
              :inputData="patient"
            ></Datatable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as CO from "@/utils/column";
import Searchtable from "@/components/testcomp/SearchTable.vue";
import Combogroup from "@/components/testcomp/ComboGroup";
import Datatable from "@/components/testcomp/DataTable.vue";
import Buttontabletop from "@/components/testcomp/ButtonTableTop.vue";

export default {
  components: {
    Searchtable,
    Datatable,
    Buttontabletop,
    Combogroup,
  },
  data() {
    return {
      search: "",
      columns: CO.DataTable,
      patient: [
        {
          clinic: "LA Medical",
          mrn: "002231",
          lname: "John",
          fname: "DOE",
          gender: "Male",
          age: "75",
          diagnosis: "Hypertension",
          careplan: "01.Weight loss ... (15d)",
          measure: "10",
          mratio: "30%",
          caretime: "12:30",
          totalAlert: "36",
          medical: "20",
          remained: "3",
        },
        {
          clinic: "LA Medical",
          mrn: "002233",
          lname: "Tom",
          fname: "DOE",
          gender: "Male",
          age: "75",
          diagnosis: "Hypertension",
          careplan: "01.Weight loss ... (15d)",
          measure: "10",
          mratio: "40%",
          caretime: "11:30",
          totalAlert: "36",
          medical: "20",
          remained: "3",
        },
        {
          clinic: "LA Medical",
          mrn: "002234",
          lname: "Sara",
          fname: "DOE",
          gender: "Female",
          age: "75",
          diagnosis: "Hypertension",
          careplan: "02.Weight loss ... (15d)",
          measure: "10",
          mratio: "40%",
          caretime: "11:30",
          totalAlert: "36",
          medical: "20",
          remained: "3",
        },
      ],
    };
  },
  methods: {
    searchEvent(e) {
      this.search = e;
      console.log(this.search);
    },
  },
};
</script>

<style>
</style>
