<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedTime"
        :label="label"
        append-icon="mdi-clock-time-four-outline"
        readonly
        outlined
        dense
        hide-details
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="computedTime"
      no-title
      @click:minute="clickMinute(computedTime)"
    >
    </v-time-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "Time",
    },
    time: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    computedTime: {
      get() {
        return this.time;
      },
      set(newValue) {
        return this.$emit("onchange", newValue);
      },
    },
  },
  methods: {
    clickMinute(value) {
      this.$refs.menu.save(value);
    },
  },
};
</script>

<style>
</style>
