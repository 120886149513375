<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="grow-list"
    scrollable
  >
    <div class="top-header">
      <button class="header-btn btn-back" @click="Close()">
        <v-icon large> mdi-close </v-icon>
      </button>
      <h1 class="dialog-title">성장 기록 리스트</h1>
    </div>
    <div class="dialog-content">
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">키(cm)</th>
              <th class="text-center">몸무게(kg)</th>
              <th class="text-center">측정일자</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in growList" :key="index">
              <td>{{ item.height || "기록없음" }}</td>
              <td>{{ item.weight || "기록없음" }}</td>
              <td>{{ item.measure_datetime_label }}</td>
              <td>
                <v-btn icon @click="clickDeleteBtn(item)">
                  <v-icon color="warning">
                    mdi-trash-can-outline
                  </v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-dialog>
</template>

<script>
import { getBodyInfoList } from "@/api/bodyInfo";
export default {
  data() {
    return {
      dialog: false,
      growList: [],
    };
  },
  methods: {
    Open() {
      this.dialog = true;
      this.getListData();
    },
    Close() {
      this.dialog = false;
    },
    async getListData() {
      const variables = {
        userIndex: this.$store.getters.getUserIndex,
      };
      const res = await getBodyInfoList(variables);
      console.log(res);
      this.growList = res;
    },
    clickDeleteBtn({ body_info_index }) {
      this.deleteBodyInfo(body_info_index);
      // alert("YYYY년 MM월 DD일 성장 정보를 삭제하시겠습니까?");
    },
    async deleteBodyInfo(body_info_index) {
      const userIndex = this.$store.getters.getUserIndex;
      try {
        const reqData = {
          userIndex,
          bodyInfoIndex: body_info_index,
        };
        await this.$store.dispatch("deleteBodyInfoData", reqData);
        alert("삭제 완료");
        this.getListData();
        this.$emit("deleteBodyInfo");
        // TODO 상위 컴포넌트에서 성장정보 재로드필요
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.grow-list {
  background: #ffffff;
  padding-top: 54px;
  .top-header {
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    position: fixed;
    width: 100%;
    background: #ffffff;
    top: 0;
    z-index: 200;
    .dialog-title {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      margin: 0;
    }
    .header-btn {
      position: absolute;
      top: 10px;
      left: 10px;
      float: left;
      .v-icon {
        color: $primary;
      }
    }
  }
  .dialog-content {
    padding: 20px;
    .v-data-table {
      height: 100%;
    }
    .v-data-table__wrapper {
      height: 100%;
      th {
        background: #f8f8f8 !important;
        color: #000000 !important;
        font-weight: 700;
        height: 40px;
      }
      td {
        text-align: center;
        .v-btn--icon {
          width: 16px;
        }
      }
    }
  }
}
</style>