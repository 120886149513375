var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VDatetimeField',{ref:"time",attrs:{"only-time":"","flat":"","time-props":{
      outlined: true,
      dense: true,
      flat: true,
      hideDetails: true,
      noTitle: true,
      label: _vm.label,
      appendIcon: 'mdi-clock-time-four-outline',
    }},model:{value:(_vm.computedTime),callback:function ($$v) {_vm.computedTime=$$v},expression:"computedTime"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }