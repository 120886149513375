export class MonthCalendar {
  dataSet = {
    month: null,
    year: null,
    compliance: false,
    bravely: false,
    noDuplicate: false,
    valid: false,
  };
  /**
   *  @description 초기화 된 리스트 리턴
   *  @param {String} year - 연도
   *  */
  setMonthCalendar(year) {
    return Array.from({ length: 12 }, (value, index) => ({
      ...this.dataSet,
      month: index + 1,
      year,
    }));
  }
  /**
   * @description 성장 미션 리스트, 업데이트 된 배열을 리턴합니다.
   * @param {Array} MonthCalendar - 월별 리스트
   * @param {Array} updateList - 업데이트 해야하는 리스트
   * @param {Number} updateYear - 리스트가 빈 배열일 경우 년도만 수정 해야함.
   * */
  updateMonthCalendar(monthCalendar, updateList, updateYear) {
    let result = monthCalendar;
    if (updateList.length < 1) {
      result = monthCalendar.map((item) => ({ ...item, year: updateYear }));
    } else {
      updateList.forEach((item) => {
        result = monthCalendar.map((element) => {
          const isEqual = element.month == item.target_month;
          element.year = item.target_year;
          if (isEqual) {
            element.valid = true;
            element.compliance = item.is_80_per_over === "Y" ? true : false;
            element.bravely = item.is_5_pcs_less === "Y" ? true : false;
            element.noDuplicate = item.is_no_overlap === "Y" ? true : false;
          }
          return element;
        });
      });
    }
    return result;
  }
}
