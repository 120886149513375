<template>
  <v-dialog v-model="dialog" content-class="popup index-sticker">
    <v-card-title>성장 미션 스티커 지급 기준</v-card-title>
    <div class="popup-content">
      <v-list subheader two-line>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="@/assets/images/sticker/sticker_compliance_active.png"
              alt=""
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> 순응도 스티커</v-list-item-title>

            <v-list-item-subtitle>
              한 달 순응도가 80 % 이상일 때 지급됩니다.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="@/assets/images/sticker/sticker_bravely_active.png"
              alt=""
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> 씩씩한 스티커</v-list-item-title>

            <v-list-item-subtitle>
              씩씩하게 주사를 맞으면 지급됩니다. [오늘의 성장 캐릭터]에서

              <img
                class="emotion"
                src="@/assets/images/emotion/bad.png"
                alt=""
              />
              입력이 5개 이하인 경우에 해당됩니다.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="@/assets/images/sticker/sticker_noDuplicate_active.png"
              alt=""
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> 투약부위 스티커 </v-list-item-title>

            <v-list-item-subtitle>
              한 달 동안 연속해서 같은 부위에 투약하지 않았을 때 지급됩니다.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.index-sticker {
  .v-card__title {
    padding: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 16px;
  }
  .v-list {
    margin-bottom: 20px;
    .v-list-item {
      padding: 0;
      .v-avatar {
        min-width: 60px !important;
        width: 60px !important;
        height: 60px !important;
        overflow: inherit;
        margin: 10px 10px 10px 0;
      }
      .v-list-item__content {
        padding: 0;
        .v-list-item__title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
        }
        .v-list-item__subtitle {
          font-size: 14px;
          line-height: 20px;
          overflow: inherit;
          white-space: inherit;
          .emotion {
            width: 20px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>