import { injectionMission } from "./index";

/** 성장 미션 데이터 조회 (연,월) */
export const getMission = (reqData) => {
  return injectionMission
    .get(
      `/missionR?userIndex=${reqData.userIndex ||
        0}&targetYear=${reqData.targetYear ||
        0}&targetMonth=${reqData.targetMonth || null}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 성장 미션 데이터 조회 (연)*/
export const getMissionList = (reqData) => {
  return injectionMission
    .get(
      `/missionList?userIndex=${reqData.userIndex ||
        0}&targetYear=${reqData.targetYear ||
        0}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
/** 성장 미션 연도 리스트 조회 */
export const getMissionYearList = (reqData) => {
  return injectionMission
    .get(`/missionYearList?userIndex=${reqData.userIndex || 0}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
// /** 유저 레벨업 팝업 닫기 이벤트 저장*/
// export const postLevelPopup = (reqData) => {
//   const req = {
//     data: reqData,
//   };
//   return injectionMission
//     .post("/userLevelPopupClose", req)
//     .then((res) => {
//       return res.data[0];
//     })
//     .catch((err) => {
//       return err;
//     });
// };
