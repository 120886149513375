export default {
  userName: "아동 이름을 입력해 주세요.",
  userParentsName: "보호자 이름을 입력해 주세요.",
  firstInjectionDate: "첫 주사 시작일을 입력해 주세요.",
  firstInjectionDateIsAfterMeasureDate:
    "측정일을 첫 주사 시작일 이후로 입력해주세요.",
  height: "키를 입력해 주세요.",
  weight: "몸무게를 입력해 주세요.",
  measureDate: "측정일자를 선택해 주세요.",
  measureTime: "측정시간을 입력해 주세요.",
  injectionTool: "투약기구를 선택해 주세요.",
  dailyInjectionDose: "하루 처방 용량을 입력해 주세요.",
  numberOfInjections: "투약 주기를 선택해 주세요.",
  medicationDay: "투약 요일별 용량을 입력해 주세요.",
  checkInjectDate: "첫 처방정보 입력일자 이후로 첫 주사 시작일을 입력해 주세요.",
};
