<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row class="patient-read-top">
          <v-col cols="7">
            <h1>
              John Doe
              <span>
                (MRNNUMBER) 60 Male / Mobile : 188652552 /
                user@example.com</span
              >
            </h1>
          </v-col>
          <v-col cols="5" class="btn-group">
            <v-btn
              small
              depressed
              class="ma-1 info--text"
              color="lighten-5 info"
            >
              SMS
            </v-btn>
            <span>00:00:00 </span>
            <v-btn
              small
              depressed
              class="ma-1 info--text"
              color="lighten-5 info"
            >
              <v-icon>mdi-pause</v-icon>
            </v-btn>
            <v-btn
              small
              depressed
              class="ma-1 info--text"
              color="lighten-5 info"
            >
              Memo
            </v-btn>
            <v-btn
              small
              depressed
              class="ma-1 info--text"
              color="lighten-5 info"
            >
              Care Note
            </v-btn>
            <v-btn
              small
              depressed
              class="ma-1 info--text"
              color="lighten-5 info"
            >
              <v-icon>mdi-stop</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><h5>Medical & Compliance Alert</h5></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!-- <v-card-title>Medical Alert</v-card-title> -->
              <h6 class="expansion-sub-title">Medical Alert</h6>
              <v-row align="center">
                <v-col cols="8">
                  <v-combobox
                    :items="itemsOne"
                    label="Select Indicator"
                    multiple
                  />
                </v-col>
                <v-col cols="4"> <Datepickerrange></Datepickerrange></v-col>
              </v-row>

              <Actiontable></Actiontable>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><h5>Care Plan</h5></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <Simpletable></Simpletable>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ><h5>Vital Sign</h5></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-radio-group v-model="radios" row>
                <v-radio
                  label="Blood Pressuer & Weight"
                  value="radio-1"
                ></v-radio>
                <v-radio
                  label="Blood Glucose & Weight"
                  value="radio-2"
                ></v-radio>
              </v-radio-group>
              <Datepickerrange></Datepickerrange>
              <ChartArea></ChartArea>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Simpletable from "@/components/testcomp/SimpleTable.vue";
import Actiontable from "@/components/testcomp/ActionTable.vue";
import Datepickerrange from "@/components/testcomp/DatePickerRange.vue";
import ChartArea from "@/components/testcomp/ChartArea.vue";
export default {
  components: {
    Simpletable,
    Actiontable,
    Datepickerrange,
    ChartArea,
  },
  data() {
    return {
      search: "",
      itemsOne: ["Blood Pressure", "Blood Gluscose"],
      radios: "radio-1",
    };
  },
  methods: {
    searchEvent(e) {
      this.search = e;
      console.log(this.search);
    },
  },
};
</script>

<style lang="scss">
.patient-read-top {
  h1 {
    span {
      font-size: 14px;
    }
  }
  .btn-group {
    text-align: right;
  }
}
// .expansion-sub-title {
//   padding: 0 0 20px 0;
// }
// .col {
//   align-items: center;
// }
</style>
