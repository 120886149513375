<template>
  <v-menu
    ref="menuTwo"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        outlined
        dense
        append-icon="mdi-calendar"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      type="month"
      selected-items-text=""
      v-model="computedDates"
      @change="changeDate"
      range
      :allowed-dates="allowedDateRule"
      :weekday-format="getDay"
      :month-format="getMonth"
      :title-date-format="getMonth"
      :header-date-format="getHeaderTitleMonth"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Date",
    },
    allowedDateRule: {
      type: Function,
      default: () => true,
    },
    dates: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    computedDates: {
      get() {
        return this.dates;
      },
      set(newValue) {
        const changeValue = this.switchDate(newValue);
        return this.$emit("onchange", changeValue);
      },
    },
    dateRangeText() {
      // console.log(this.dates.join(","));
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    switchDate(value) {
      let result = value;
      // [시작일, 종료일] 다 입력되었을때 날짜 순서가 반대일경우 날짜 바꾸기
      if (value.length === 2) {
        const [fromDay, toDay] = value;
        if (toDay < fromDay) {
          result = [toDay, fromDay];
        }
      }
      return result;
    },
    changeDate() {
      this.$refs.menuTwo.save(this.dates);
    },
    getDay(date) {
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getMonth(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];

      let i = new Date(date).getMonth(date);
      return monthName[i];
    },
    getHeaderTitleMonth(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];
      let i = new Date(date).getMonth(date);
      const year = new Date(date).getFullYear(date);
      const month = monthName[i];
      return `${year}년 ${month}`;
    },
  },
};
</script>

<style>
</style>
