<template>
  <div class="page myinfo">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">나의 정보 입력하기</h1>
    </div>
    <div class="page-content">
      <h2 class="page-sub-title">나의 정보</h2>
      <!-- <v-row class="info-row">
        <v-col cols="12">
          <v-text-field
            ref="userName"
            :value="userName"
            @input="onchangeUserName"
            label="아동 이름 (필수)"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-row>
      <v-row class="info-row">
        <v-col cols="12">
          <v-text-field
            ref="userParentsName"
            :value="userParentsName"
            @input="onchangeUserParentsName"
            label="보호자 이름 (필수)"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-row> -->
      <v-row class="info-row">
        <v-col cols="12">
          <DatePicker
            label="첫 주사 시작일 (필수)"
            :date="firstInjectionDate"
            @onchange="onchangeFirstInjectionDate"
          />
        </v-col>
      </v-row>

      <h2 class="page-sub-title mt-8">
        성장 정보
        <v-btn
          v-if="$store.getters.getUserIndex"
          color="secondary"
          @click="clickGrowList"
          small
          depressed
          >성장 기록 보기</v-btn
        >
      </h2>

      <v-row class="info-row">
        <v-col cols="12">
          <v-text-field
            ref="height"
            :value="height"
            @input="onchangeHeight"
            type="number"
            label="키"
            outlined
            dense
            hide-details
            onkeydown="javascript: return event.keyCode == 69 ? false : true"
            suffix="cm"
          />
        </v-col>
      </v-row>
      <v-row class="info-row">
        <v-col cols="12">
          <v-text-field
            ref="weight"
            :value="weight"
            @input="onchangeWeight"
            type="number"
            label="몸무게"
            outlined
            dense
            hide-details
            onkeydown="javascript: return event.keyCode == 69 ? false : true"
            suffix="kg"
          />
        </v-col>
      </v-row>
      <v-row class="info-row">
        <v-col cols="6">
          <DatePicker
            label="측정일자"
            :date="measureDate"
            :allowedDateRule="
              (val) =>
                val <= $moment().format('YYYY-MM-DD') &&
                val >= $moment(firstInjectionDate).format('YYYY-MM-DD')
            "
            @onchange="onchangeMeasureDate"
          />
        </v-col>
        <v-col cols="6">
          <!-- <TimePicker
            label="측정 시간"
            :time="measureTime"
            @onchange="onchangeMeasureTime"
          /> -->
          <TimePickerInput
            label="측정시간 (24시간제)"
            :time="measureTime"
            @onchange="onchangeMeasureTime"
          />
        </v-col>
      </v-row>

      <h2 class="page-sub-title mt-8">
        처방 정보
        <v-btn
          v-if="$store.getters.getUserIndex"
          color="secondary"
          @click="clickPrescriptionList"
          small
          depressed
          >처방 기록 보기</v-btn
        >
      </h2>
      <p class="row-guide">
        처방 용량 및 투약 횟수 변경은 반드시 주치의와 상담 후 변경해주세요.
      </p>
      <v-row class="info-row">
        <v-col cols="12">
          <v-select
            v-model="injectionTool"
            @input="onchangeInjectionTool"
            label="투약 기구 선택 (필수)"
            :items="injectionToolItems"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="info-row">
        <v-col cols="12">
          <v-text-field
            label="하루 처방 용량 (필수)"
            type="number"
            v-model="dailyInjectionDose"
            @input="onchangeInjectionDose"
            @blur="blurInjectionDose"
            onkeydown="javascript: return event.keyCode == 69 ? false : true"
            outlined
            dense
            hide-details
            suffix="IU"
          />
        </v-col>
      </v-row>
      <v-row class="info-row">
        <v-col cols="12">
          <v-select
            label="투약 횟수 선택 (필수)"
            v-model="numberOfInjections"
            @input="onchangeNumberOfInjections"
            :items="numberOfInjectionsItems"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <h4 class="info-row-title mt-4">투약 요일별 용량(mg) (필수)</h4>
      <p class="row-guide">
        <span class="c-red">
          휴약일은 변경할 수 있습니다. *mg 옆 화살표(
          <img
            class="img-handler"
            src="@/assets/images/common/icon_handler_arrow.png"
            alt=""
          />
          )를 클릭해 움직여주세요.
        </span>
        <br />
        {{ computedInjectionDose }}
      </p>
      <v-row class="info-row">
        <v-col cols="2">
          <div
            class="list-group-item"
            v-for="(item, index) in week"
            :key="index"
          >
            {{ item }}
          </div>
        </v-col>
        <v-col cols="10">
          <h3 class="draggable-info">Draggable {{ draggingInfo }}</h3>
          <draggable
            :list="medicationDay"
            class="list-group"
            handle=".handle"
            ghost-class="ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
          >
            <div
              class="list-group-item"
              v-for="item in medicationDay"
              :key="item.id"
            >
              <div class="input-group">
                <input
                  :disabled="item.disabled"
                  :class="`medicationDay_${item.id}`"
                  :placeholder="item.disabled ? '휴약일' : ''"
                  type="float"
                  v-model="item.text"
                  @input="onchangeMedicationItem($event, item.id)"
                />
                <span class="input-group-addon">mg</span>
              </div>
              <span class="handle">
                <img src="@/assets/images/common/icon_handler_arrow.png" alt=""
              /></span>
            </div>
          </draggable>
        </v-col>
      </v-row>
      <v-row class="info-row">
        <v-col cols="12">
          <DatePicker
            label="처방정보 입력일자"
            :date="prescriptionDate"
            :allowedDateRule="(val) => val <= $moment().format('YYYY-MM-DD')"
            @onchange="onchangePrescriptionDate"
          />
        </v-col>
      </v-row>
      <p class="row-guide">
        입력하신 처방정보 입력일자 기준으로 과거 일자로 수정이 불가합니다.
      </p>
    </div>
    <div class="bottom-btn">
      <v-btn color="primary" depressed large @click="clickSaveBtn"> 저장</v-btn>
    </div>

    <GrowListDialog ref="GrowListDialog" @deleteBodyInfo="deleteBodyInfo" />
    <PrescriptionListDialog ref="PrescriptionListDialog" />

    <FreeInputPopup ref="FreeInputPopup" :injectionTool="injectionTool" />
    <ChangeCriteriaPopup
      :prescriptionDate="prescriptionDate"
      ref="ChangeCriteriaPopup"
    />
    <Common_Popup
      :openCloseStatus="commonPopupStatus"
      :text="commonPopupText"
      @onchangeOpenCloseStatus="onchangeOpenCloseStatus"
      @clickCloseBtn="clickCommonPopupCloseBtn"
    />
    <WeekTotalPopup ref="WeekTotalPopup" />

    <!-- 투약 용량 리마인드를 위한 팝업 -->
    <RemindPopup ref="RemindPopup" />
  </div>
</template>

<script>
import DatePicker from "@/components/common/vuetify/DatePicker.vue";
import Sortable from "sortablejs";
import moment from "moment";
import store from "@/store/index";
import draggable from "vuedraggable";
import {
  injectionToolItems,
  numberOfInjectionsItems,
} from "@/utils/selectItems";
import {
  EasyPod,
  Aluetta,
  MedicationDay,
  MedicationEquipment,
} from "@/components/common/model/medicationDay";
import GrowListDialog from "@/components/common/dialog/GrowList_Dialog.vue";
import PrescriptionListDialog from "@/components/common/dialog/PrescriptionList_Dialog.vue";
import FreeInputPopup from "@/components/common/popup/FreeInput_Popup.vue";
import ChangeCriteriaPopup from "@/components/common/popup/ChangeCriteria_Popup.vue";
import Common_Popup from "@/components/common/popup/Common_Popup.vue";
import requiredText from "@/components/common/myinfo/requiredText";
import WeekTotalPopup from "@/components/common/popup/WeekTotal_Popup.vue";
import RemindPopup from "@/components/common/popup/Remind_Popup.vue";
import TimePicker from "@/components/common/vuetify/TimePicker.vue";
import TimePickerInput from "@/components/common/vuetify/TimePickerInput.vue";

export default {
  components: {
    DatePicker,
    draggable,
    GrowListDialog,
    PrescriptionListDialog,
    FreeInputPopup,
    ChangeCriteriaPopup,
    Common_Popup,
    WeekTotalPopup,
    RemindPopup,
    TimePicker,
    TimePickerInput,
  },
  data() {
    return {
      // time: null,
      // menu: false,
      week: [
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일",
        "일요일",
      ],
      dragging: false,
      Equipment: new MedicationEquipment(),
      // 나의 정보
      userName: null, //  (필수)
      userParentsName: null, //  (필수)
      firstInjectionDate: moment().format("YYYY-MM-DD"), //  (필수)
      // 성장 정보
      height: null, //  (필수)
      weight: null, //  (필수)
      measureDate: moment().format("YYYY-MM-DD"),
      measureTime: moment().format("HH:mm"),
      // 처방 정보
      injectionToolItems,
      numberOfInjectionsItems,
      injectionTool: null, //  (필수)
      dailyInjectionDose: null, //  (필수)
      numberOfInjections: null, //  (필수)
      // 투약 요일
      medicationDay: [], //  (필수)
      prescriptionDate: null,

      allowPrescriptionDate: null, // 과거 처방정보 입력일자 이력
      allowMeasureDatetime: null, // 과거 측정일자 이력
      // 공통 팝업
      commonPopupStatus: false,
      commonPopupText: "",

      // Flag (팝업을 오픈한 이력이 있는지 확인하는 용도)
      modifiedPrescription: false,
      modifiedCriteriaPopup: false,
      modifiedGrowInfo: false,
      modifiedMeasureDatePopup: false,
      /** 첫 처방일 (조회한 후 저장할때 첫 주사일과 비교하기 위한 변수) */
      firstPrescriptionDate: null,
      /** 투약기록 여부 (처방정보 입력일자 변경할때 투약기록이 없을경우 과거 날짜 update 가능, 투약기록이 있을경우 과거날짜로 변경 불가능 및 팝업 오픈)*/
      lastInjectionIndex: null,
      /** 마지막 투약 날짜 (처방정보 변경할때 확인하여 마지막 투약정보 이전날짜로 처방일 변경 불가 처리) */
      lastInjectionDate: null,
    };
  },
  mounted() {
    this.checkUserExists();
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    computedInjectionDose() {
      return this.injectionTool === "aluetta"
        ? "mg을 소수점 첫째자리까지 입력해주세요"
        : "mg을 소수점 둘째자리까지 입력해주세요";
    },
  },
  methods: {
    clickBackBtn() {
      this.$router.push("app");
    },
    clickSaveBtn() {
      // 필수 입력 체크
      if (this.checkRequired()) return;
      // 저장 로직
      // console.log("save");
      this.saveMyInfo();
    },
    clickCommonPopupCloseBtn() {
      this.commonPopupStatus = false;
    },
    clickGrowList() {
      this.$refs.GrowListDialog.Open();
    },
    clickPrescriptionList() {
      this.$refs.PrescriptionListDialog.Open();
    },
    onchangeUserName(value) {
      this.$refs.userName.lazyValue = value
        .replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, "") // 특수문자
        .replace(/\s/g, "") // 공백
        .replace(/[0-9]/g, ""); // 숫자
      this.userName = this.$refs.userName.lazyValue;
    },
    onchangeUserParentsName(value) {
      this.$refs.userParentsName.lazyValue = value
        .replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, "") // 특수문자
        .replace(/\s/g, "") // 공백
        .replace(/[0-9]/g, ""); // 숫자
      this.userParentsName = this.$refs.userParentsName.lazyValue;
    },
    onchangeOpenCloseStatus(value) {
      this.commonPopupStatus = value;
    },
    onchangeFirstInjectionDate(value) {
      this.firstInjectionDate = value;
    },
    onchangeHeight(value) {
      this.modifiedGrowInfo = true;
      const result = value.replace(
        /[0-9]{4}$/g,
        value.substring(0, value.length - 1)
      ); // 3자리 이상 제한
      this.$refs.height.lazyValue = result;
      this.height = result;
      if (result > 300) {
        this.$refs.height.lazyValue = ""; // 300 이상 제한
        this.height = "";
      }
    },
    onchangeWeight(value) {
      this.modifiedGrowInfo = true;
      const result = value.replace(
        /[0-9]{4}$/g,
        value.substring(0, value.length - 1)
      ); // 3자리 이상 제한
      this.$refs.weight.lazyValue = result;
      this.weight = result;
      if (result > 300) {
        this.$refs.weight.lazyValue = ""; // 300키로 이상 제한
        this.weight = "";
      }
    },
    onchangeGrowInfo() {
      this.modifiedGrowInfo = true;
    },
    onchangeMeasureDate(value) {
      this.measureDate = value;
    },
    onchangeMeasureTime(value) {
      this.measureTime = value;
    },
    onchangePrescriptionDate(value) {
      this.prescriptionDate = value;
      // :이유 바로 변경하게 되면 warning 이 뜰수 있다.
      setTimeout(() => {
        this.prescriptionDate = this.checkPrescriptionDate(value);
      }, 100);
    },
    onchangeInjectionDose(value) {
      if (value > 20) this.dailyInjectionDose = null; // 하루처방용량 20 이상입력할 경우 빈값처리!
      this.onchangePrescriptionInfo();
    },
    blurInjectionDose() {
      if (
        this.injectionTool &&
        this.dailyInjectionDose &&
        this.numberOfInjections
      ) {
        this.checkFreeInputPopupOpen();
      }
    },
    onchangeInjectionTool() {
      this.onchangePrescriptionInfo();
      if (
        this.injectionTool &&
        this.dailyInjectionDose &&
        this.numberOfInjections
      ) {
        this.checkFreeInputPopupOpen();
      }
    },
    onchangeNumberOfInjections() {
      this.onchangePrescriptionInfo();
      if (
        this.injectionTool &&
        this.dailyInjectionDose &&
        this.numberOfInjections
      ) {
        this.checkFreeInputPopupOpen();
      }
    },
    onchangePrescriptionInfo() {
      if (
        this.injectionTool &&
        this.dailyInjectionDose &&
        this.numberOfInjections
      ) {
        this.setMedicationDay();
      }
      this.modifiedPrescription = true;
    },
    // 투약요일 변경할때 이벤트 감지
    onchangeMedicationItem(event, index) {
      this.modifiedPrescription = true; //TODO 처방정보 변경 Flag
      const value = event.target.value;
      let pattern;
      if (this.injectionTool === "easyPod") {
        pattern = /^\d*.?\d{0,2}$/;
      } else {
        pattern = /^\d*.?\d{0,1}$/;
      }
      if (!pattern.test(value)) this.medicationDay[index].text = null;
    },
    openFreeInputPopup() {
      this.$refs.FreeInputPopup.Open();
    },
    checkPrescriptionDate(date) {
      const dateIsBeforeLastInjection = this.$moment(date).isBefore(
        this.allowPrescriptionDate
      );
      if (this.lastInjectionIndex && dateIsBeforeLastInjection) {
        this.commonPopupStatus = true;
        this.commonPopupText =
          "투약기록이 있는 경우, [마지막 처방정보 입력일자] 이전의 날짜로 수정할 수 없습니다.";
        return this.allowPrescriptionDate;
      } else {
        return date;
      }
    },
    checkFreeInputPopupOpen() {
      const medicationEquipment = this.Equipment.createEquipment(
        this.injectionTool
      );
      let dailyInjectionDose = Number(this.dailyInjectionDose);
      let numberOfInjections = Number(this.numberOfInjections);

      const updateMedicationDay = medicationEquipment.checkMedicationDay(
        dailyInjectionDose,
        numberOfInjections
      );
      if (!updateMedicationDay) this.openFreeInputPopup();
    },
    checkRequired() {
      const userIndex = store.getters.getUserIndex;
      const popupOpen = (columnName) => {
        this.commonPopupStatus = true;
        this.commonPopupText = requiredText[columnName];
        return true;
      };
      // if (!this.userName) return popupOpen("userName");
      // if (!this.userParentsName) return popupOpen("userParentsName");
      if (!this.firstInjectionDate) return popupOpen("firstInjectionDate");
      // if (!this.height) return popupOpen("height");
      // if (!this.weight) return popupOpen("weight");
      if (!this.measureDate) return popupOpen("measureDate");
      if (!this.measureTime) return popupOpen("measureTime");
      if (!this.injectionTool) return popupOpen("injectionTool");
      if (!this.dailyInjectionDose) return popupOpen("dailyInjectionDose");
      if (!this.numberOfInjections) return popupOpen("numberOfInjections");
      if (this.medicationDay.every((item) => !item.text))
        return popupOpen("medicationDay");
      if (this.medicationDay.some((item) => item.text === ""))
        return popupOpen("medicationDay");
      //  첫 주사일이 성장정보 측정일자 이후 일경우 저장막기
      const firstInjectionDateIsAfterMeasureDate = this.$moment(
        this.firstInjectionDate
      ).isAfter(this.$moment(this.measureDate), "days");
      if (firstInjectionDateIsAfterMeasureDate)
        return popupOpen("firstInjectionDateIsAfterMeasureDate");

      // 첫 주사일과 첫 처방일 날짜 비교 (update, insert 에 따라 첫 처방일이 달라짐)
      const firstPrescriptionDate = userIndex
        ? this.firstPrescriptionDate
        : this.prescriptionDate;
      const checkDate = this.$moment(firstPrescriptionDate).isSameOrBefore(
        this.$moment(this.firstInjectionDate)
      );
      if (!checkDate) return popupOpen("checkInjectDate");

      // 이전 저장 이력이 있을경우
      if (userIndex) {
        // /** 측정일자는 변경하지 않고, 그 외 측정정보(키, 몸무게 등)만 수정했을 경우는 저장 시 “성장정보 측정일자를 확인해주세요.” 주의 팝업 오픈 */
        const isEqualMeasureDate =
          this.allowMeasureDatetime ===
          this.$moment(`${this.measureDate} ${this.measureTime}`).format(
            "YYYY-MM-DD HH:mm"
          );
        if (isEqualMeasureDate && this.modifiedGrowInfo) {
          // if (!this.modifiedMeasureDatePopup) {
          this.commonPopupStatus = true;
          this.commonPopupText = "성장정보 측정일자를 확인해주세요.";
          // this.modifiedMeasureDatePopup = true;
          return true;
          // }
        }

        // 마지막 투약일자보다 이전날짜에 처방일로 변경할 경우 팝업 띄우고 저장불가 처리.
        const ModifiedPrescriptionDate =
          this.allowPrescriptionDate !== this.prescriptionDate;
        if (
          (ModifiedPrescriptionDate || this.modifiedPrescription) &&
          this.lastInjectionDate
        ) {
          const isBeforeLastInjection = this.$moment(
            this.prescriptionDate
          ).isSameOrBefore(this.$moment(this.lastInjectionDate));
          console.log(isBeforeLastInjection);
          if (isBeforeLastInjection) {
            this.commonPopupStatus = true;
            this.commonPopupText = `마지막으로 기록된 투약날짜는 ${this.lastInjectionDate} 입니다. [처방정보 입력일자]는 마지막으로 투약기록한 날짜 이후로 설정해 주세요.`;
            this.modifiedCriteriaPopup = true; // ChangeCriteriaPopup 팝업 오픈되지않도록 처리
            return true;
          }
        }
        /** 처방정보 입력일자는 변경하지 않고, 그 외 처방정보(처방용량, 투약 요일 등)만 수정했을 경우는 저장 시 “처방 일자를 확인해주세요.” 주의 팝업 오픈 */
        /** 11.07 #60 이슈로 인해 사용하지 않게됨. */
        const isEqual = this.allowPrescriptionDate === this.prescriptionDate;
        if (isEqual && this.modifiedPrescription) {
          if (!this.modifiedCriteriaPopup) {
            this.$refs.ChangeCriteriaPopup.Open();
            this.modifiedCriteriaPopup = true;
            return true;
          }
        }
      }

      return false;
    },
    async checkUserExists() {
      const userExists = store.getters.getUserIndex;
      try {
        if (userExists) {
          const myInfo = await store.dispatch("getMyInfo");
          const bodyInfo = await store.dispatch("getLastBodyInfoData");
          const [prescription] = await store.dispatch(
            "getLastPrescriptionData",
            {}
          );
          const lastInjectionInfo = await store.dispatch(
            "getLastInjectionForPrescription"
          );
          console.log(lastInjectionInfo);
          console.log("조회완료");
          this.bindDataSet({
            ...myInfo,
            ...bodyInfo,
            ...prescription,
            ...lastInjectionInfo,
          });
        } else {
          this.initMedicationDay();
        }
      } catch (err) {
        console.log(err);
      }
    },
    initMedicationDay() {
      const { list: newMedicationDay } = new MedicationDay();
      this.medicationDay = newMedicationDay;
    },
    setMedicationDay() {
      const medicationEquipment = this.Equipment.createEquipment(
        this.injectionTool
      );
      let dailyInjectionDose = Number(this.dailyInjectionDose);
      let numberOfInjections = Number(this.numberOfInjections);

      const updateMedicationDay = medicationEquipment.checkMedicationDay(
        dailyInjectionDose,
        numberOfInjections
      );
      if (updateMedicationDay) {
        this.medicationDay = updateMedicationDay;
        console.log(this.medicationDay);
      } else {
        // this.medicationDay = new MedicationDay().list;
        const arry = Array.from({ length: 7 }, () => -1); //  [-1,-1,-1,-1,-1,-1,-1]
        const result = arry.map((item, index) => {
          if (index >= numberOfInjections) {
            return -1;
          } else {
            return "";
          }
        });
        this.medicationDay = new MedicationDay().setMedicationDay(result);
        console.log(this.medicationDay);
        // this.openFreeInputPopup();
      }
    },
    /** 조회시 데이터 바인딩 */
    bindDataSet(res) {
      console.log(res);
      // 나의 정보
      // this.userName = res.user_name;
      // this.userParentsName = res.user_parents_name;
      this.firstInjectionDate = res.first_injection_date;
      this.firstPrescriptionDate = res.first_prescription_date; // 첫 처방일 저장
      this.lastInjectionIndex = res.last_injection_index; // 투약기록 여부
      // 마지막 투약기록 확인
      this.lastInjectionDate =
        res.is_injected === "Y" ? res.injection_date : null;
      // 성장 정보
      this.height = res.height;
      this.weight = res.weight;
      const measureDate = moment(res.measure_datetime).format("YYYY-MM-DD");
      const measureTime = moment(res.measure_datetime).format("HH:mm");
      this.measureDate = measureDate;
      this.measureTime = measureTime;
      // 처방 정보
      this.injectionTool = res.injection_tool;
      this.dailyInjectionDose = res.daily_injection_dose;
      this.numberOfInjections = String(res.number_of_injections_per_week);
      const medicationDay = new MedicationDay();
      this.medicationDay = medicationDay.setMedicationDay([
        res.mon,
        res.tue,
        res.wed,
        res.thu,
        res.fri,
        res.sat,
        res.sun,
      ]);
      this.prescriptionDate = res.prescription_date;
      this.allowPrescriptionDate = res.prescription_date; // 이전 처방정보 입력일자
      this.allowMeasureDatetime = res.measure_datetime; // 이전 측정일자
    },
    async saveMyInfo() {
      const myInfoData = {
        userName: "",
        userParentsName: "",
        firstInjectionDate: this.firstInjectionDate,
      };
      const bodyInfo = {
        height: this.height || null,
        weight: this.weight || null,
        measureDatetime: `${this.measureDate} ${this.measureTime}`,
      };
      const day = this.medicationDay.map((item) => {
        return item.text ? item.text : -1;
      });
      const Prescription = {
        injectionTool: this.injectionTool,
        dailyInjectionDose: this.dailyInjectionDose,
        numberOfInjections: this.numberOfInjections,
        prescriptionDate: this.prescriptionDate,
        mon: day[0],
        tue: day[1],
        wed: day[2],
        thu: day[3],
        fri: day[4],
        sat: day[5],
        sun: day[6],
      };
      // try catch
      try {
        const userIndex = await store.dispatch("saveMyInfo", myInfoData);
        if (userIndex) {
          bodyInfo.userIndex = userIndex;
          Prescription.userIndex = userIndex;

          if (this.height || this.weight)
            await store.dispatch("saveBodyInfo", bodyInfo);
          await store.dispatch("savePrescription", Prescription);
          console.log("저장완료");
          this.$router.push({ name: "app" });
        } else {
          // error 가 -1 일경우에 처리해야함.
          this.commonPopupStatus = true;
          this.commonPopupText = "동일한 사용자가 존재합니다.";
          throw new Error("저장에 실패 했습니다.");
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkMove: function (e) {
      this.modifiedPrescription = true;
      console.log("Future index: " + e.draggedContext.futureIndex);
    },
    deleteBodyInfo() {
      this.getLastBodyInfoAndBindData();
    },
    async getLastBodyInfoAndBindData() {
      const bodyInfo = await store.dispatch("getLastBodyInfoData");
      // 성장 정보
      this.height = bodyInfo.height;
      this.weight = bodyInfo.weight;
      const measureDate = moment(bodyInfo.measure_datetime).format(
        "YYYY-MM-DD"
      );
      const measureTime = moment(bodyInfo.measure_datetime).format("HH:mm");
      this.measureDate = measureDate;
      this.measureTime = measureTime;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.myinfo {
  .page-sub-title {
    .v-btn {
      float: right;
      margin-top: -5px;
    }
  }
  .info-row-title {
    font-size: 14px;
    color: $primary;
    font-weight: 700;
  }
  .info-row {
    margin-bottom: 10px;
    ul {
      list-style: none;
      padding-left: 0;
    }
  }
  .row-guide {
    .img-handler {
      width: 8px;
      vertical-align: middle;
    }
  }
  .draggable-info {
    display: none;
  }
  .list-group {
    .list-group-item {
      justify-content: end;
    }
  }
  .list-group-item {
    // padding: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    .handle {
      padding: 15px 10px 15px 15px;
      img {
        width: 10px;
      }
    }
    .input-group {
      position: relative;
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: middle;
    }
    input {
      position: relative;
      z-index: 2;
      float: left;
      width: 100%;
      margin-bottom: 0;
      display: table-cell;
      padding: 6px 12px;
      height: 40px;
      border-radius: 4px;
      margin: 0;

      &:disabled {
        background-color: #ccc;
        color: #ccc;
        border: 1px solid #ccc;
      }
    }
    .input-group-addon {
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
}
</style>