<template>
  <div class="page diary">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">싸이젠 투약 다이어리</h1>
    </div>
    <div class="page-content">
      <div class="content-intro">
        <v-row>
          <v-col cols="4">
            <Character
              :characterLevel="characterLevel"
              :starLevel="starLevel"
            />
          </v-col>
          <v-col cols="8">
            <h6>싸이젠 주사를 기록해 주세요!</h6>
            <p v-html="feedbackText(monthSummaryPercent)"></p>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          class="w-100"
          depressed
          large
          @click="PageTo('injection_detail')"
        >
          <v-icon small>mdi-pencil</v-icon>
          투약 기록하기
        </v-btn>
      </div>
      <div class="grey-card mt-4">
        <div class="card-title">
          <b>투약기록</b>
          <span class="c-prm ml-4">{{ startDay }} - {{ endDay }}</span>
        </div>
        <v-row>
          <v-col cols="6" class="progress-text">
            <v-row>
              <v-col cols="6" class="c-prm inject-num">{{
                injectedDaysCount
              }}</v-col>
              <v-col cols="6" class="planned-num"
                >/ {{ expectedDaysCount }}</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="6" class="inject-label"
                ><span class="c-prm">투여</span></v-col
              >
              <v-col cols="6" class="planned-label"><span>예정</span></v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="progress-graph">
            <v-progress-circular
              :rotate="-90"
              :size="110"
              :width="10"
              :value="monthSummaryPercent"
              color="primary"
            >
              {{ monthSummaryPercent }} <span>%</span>
            </v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div class="calendar-area mt-4">
        <div class="index-btn-wrap">
          <v-btn icon color="secondary" class="btn-index" @click="openIndex">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </div>
        <CalendarPicker
          :date="date"
          @onchange="onchangeDate"
          @onchangePickerDate="onchangePickerDate"
          @clickDate="clickDate"
          :functionEvents="functionEvents"
          :pickerDate="pickerDate"
        />
        <!-- <v-date-picker
          v-model="date"
          :events="functionEvents"
          @click:date="clickDate"
          :picker-date.sync="pickerDate"
          event-color="green lighten-1"
          class="calendar"
          full-width
          no-title
          :weekday-format="getDay"
          :month-format="getMonth"
          :title-date-format="getMonth"
          :header-date-format="getHeaderTitleMonth"
        ></v-date-picker> -->
      </div>
      <InjectionInfo
        :selectedDate="selectedDate"
        :injectionSite="injectionSite"
        :todayGrowth="todayGrowth"
        :isBreakDay="isBreakDay"
        :toBeInject="toBeInject"
        :showInjectBtn="showInjectBtn"
        @clickInjectionSaveBtn="clickInjectionSaveBtn"
        @clickInjectionReadBtn="clickInjectionReadBtn"
      />
    </div>
    <IndexCalendarPopup ref="IndexCalendarPopup" />
  </div>
</template>

<script>
import IndexCalendarPopup from "@/components/common/popup/IndexCalendar_Popup.vue";
import moment from "moment";
import "moment/locale/ko";
import feedbackText from "@/components/common/injection/feedbackText";
import { getInjectionLog, getInjectionLogList } from "@/api/injectionLog";
import { getLastPrescription } from "@/api/prescription";
import { Calendar } from "@/components/common/model/Calendar";
import InjectionInfo from "@/components/common/injection/InjectionInfo.vue";
import Character from "@/components/common/growRecord/Character.vue";
import CalendarPicker from "@/components/common/vuetify/CalendarPicker.vue";
export default {
  components: { IndexCalendarPopup, InjectionInfo, Character, CalendarPicker },
  data() {
    return {
      moment,
      calendar: new Calendar(),
      feedbackText,
      characterLevel: null,
      starLevel: null,

      date: moment().format("YYYY-MM-DD"),
      pickerDate: null,
      selectedDate: moment().format("YYYY-MM-DD"),
      // view data
      startDay: null,
      endDay: null,
      injectedDaysCount: null,
      expectedDaysCount: null,
      monthSummaryPercent: 90,

      // flag
      isBreakDay: false,
      toBeInject: false,
      showInjectBtn: false,
      // injection detail
      injectionSite: null,
      todayGrowth: null,
      //  calendar Array
      completeArray: [],
      partingArray: [],
      breakArray: [],
      missingArray: [],
      cartridgeArray: [],

      calendarList: [],
      firstInjectionDate: null,
      injectionIndex: null,
    };
  },
  watch: {
    // pickerDate(value) {
    //   if (!value) return;
    //   console.log(value);
    //   const targetYear = this.$moment(value).format("YYYY");
    //   const targetMonth = this.$moment(value).format("MM");
    //   this.getCalenderData(targetYear, targetMonth);
    // },
  },
  async mounted() {
    await this.getLevelUpData();
    await this.getInjectionLogData();
    await this.getCalenderData();
    await this.bindInjectionDetail(this.$moment().format("YYYY-MM-DD"));
  },
  methods: {
    clickBackBtn() {
      this.$router.push("app");
    },
    clickDate(value) {
      this.selectedDate = value;
      console.log(value);
      this.bindInjectionDetail(value);
      setTimeout(() => {
        this.bottomScroll();
      }, 500);
    },
    bottomScroll() {
      const special = document.getElementById("inject-detail");
      special.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    clickInjectionSaveBtn() {
      this.$router.push({
        name: "injection_detail",
        params: {
          enterFromDiary: "insert",
          date: this.selectedDate,
          injectionIndex: this.injectionIndex, // 투약하지않음 저장할경우
        },
      });
    },
    clickInjectionReadBtn() {
      this.$router.push({
        name: "injection_detail",
        params: {
          enterFromDiary: "update",
          injectionIndex: this.injectionIndex,
        },
      });
    },
    onchangeDate(value) {
      this.date = value;
    },
    onchangePickerDate(value) {
      this.pickerDate = value;
      if (!value) return;
      console.log(value);
      const targetYear = this.$moment(value).format("YYYY");
      const targetMonth = this.$moment(value).format("MM");
      this.getCalenderData(targetYear, targetMonth);
    },
    // TODO 처방정보 입력일자에 따라 휴약일을 지정해주어야 함.
    initCalendar(date, InjectionLogList, PrescriptionList, firstInjectionDate) {
      const calendarList = this.calendar.setCalendarList(date);
      // console.log(this.calendar.setCalendarList(date));
      const updateList = this.calendar.updateCalendarList(
        calendarList,
        InjectionLogList,
        PrescriptionList
      );
      console.log(updateList);
      this.bindCalendar(updateList, firstInjectionDate);
    },
    bindCalendar(updateList, firstInjectionDate) {
      // 초기화
      this.completeArray = [];
      this.partingArray = [];
      this.breakArray = [];
      this.missingArray = [];
      this.cartridgeArray = [];

      this.calendarList = updateList;
      const isMissingDay = (item) => {
        const day =
          String(item.day).length === 1 ? 0 + String(item.day) : item.day; // 이유: moment에 입력할때 2022-08-9 로 입력하게 되면 warning 문구 발생
        return (
          item.isInjectionStatus === "default" &&
          !item.isBreakDay &&
          this.$moment(firstInjectionDate).isSameOrBefore(
            this.$moment(`${item.year}-${item.month}-${day}`)
          ) && // 주사시작일부터 반영
          this.$moment().isAfter(
            this.$moment(`${item.year}-${item.month}-${day}`),
            "day"
          )
        );
      };
      const isBreakDay = (item) => {
        const day =
          String(item.day).length === 1 ? 0 + String(item.day) : item.day; // 이유: moment에 입력할때 2022-08-9 로 입력하게 되면 warning 문구 발생
        return (
          item.isBreakDay &&
          this.$moment(firstInjectionDate).isSameOrBefore(
            this.$moment(`${item.year}-${item.month}-${day}`)
          )
          // 주사시작일부터 반영
        );
      };

      // 카트리지 교체일
      updateList.filter((item) => {
        if (item.isReplaceCartridge) this.cartridgeArray.push(item.day);
      });
      // 주사맞음 설정
      updateList.filter((item) => {
        if (item.isInjectionStatus === "complete")
          this.completeArray.push(item.day);
      });
      // // 부분주사
      updateList.filter((item) => {
        if (item.isInjectionStatus === "incomplete")
          this.partingArray.push(item.day);
      });
      // 주사 놓침
      updateList.filter((item) => {
        if (isMissingDay(item)) this.missingArray.push(item.day);
      });
      // 휴약일 설정
      updateList.filter((item) => {
        if (isBreakDay(item)) this.breakArray.push(item.day);
      });
    },
    async getCalenderData(targetYear = null, targetMonth = null) {
      const year = targetYear ? targetYear : this.$moment().format("YYYY");
      const month = targetMonth ? targetMonth : this.$moment().format("MM");
      const date = `${year}-${month}`;
      console.log(date);
      const variable = {
        userIndex: this.$store.getters.getUserIndex,
        targetYear: year,
        targetMonth: month,
      };
      const InjectionLogList = await getInjectionLogList(variable);
      const PrescriptionList = await getLastPrescription(variable);
      const myInfo = await this.$store.dispatch("getMyInfo");

      this.firstInjectionDate = myInfo.first_injection_date;
      // 날짜 넘겨받기
      this.initCalendar(
        date,
        InjectionLogList,
        PrescriptionList,
        this.firstInjectionDate
      );
    },

    functionEvents(date) {
      // console.log(date);
      const [, , day] = date.split("-");
      if (this.partingArray.includes(parseInt(day))) {
        if (this.cartridgeArray.includes(parseInt(day))) {
          return ["parting cartridge-change", ""];
        } else {
          return ["parting", ""];
        }
      }
      if (this.completeArray.includes(parseInt(day))) {
        if (this.cartridgeArray.includes(parseInt(day))) {
          return ["complete cartridge-change", ""];
        } else {
          return ["complete", ""];
        }
      }
      if (this.breakArray.includes(parseInt(day))) return ["break", ""];
      if (this.missingArray.includes(parseInt(day))) return ["missing", ""];
      return false;
    },
    PageTo(url) {
      this.$router.push(url);
    },
    openIndex() {
      this.$refs.IndexCalendarPopup.Open();
    },
    /** @param {date} date - 날짜 입력 (ex. 2022-08-30) */
    bindInjectionDetail(date) {
      // 초기화
      this.injectionSite = null;
      this.todayGrowth = null;
      this.isBreakDay = false;
      this.showInjectBtn = false;
      this.toBeInject = false;
      this.injectionIndex = null;
      // 컴포넌트로 데이터 바인딩 7가지 경우 (과거 일자 클릭 투약 기록이 있는 경우, 과거 일자 클릭 투약 기록 없는 경우, 과거일자 휴약일인 경우 )
      const isFirstInjectionAfter = this.$moment(date).isSameOrAfter(
        this.$moment(this.firstInjectionDate)
      );
      console.log(isFirstInjectionAfter);
      const isPast = this.$moment().isAfter(this.$moment(date), "day");
      const isPresent = this.$moment().isSame(this.$moment(date), "day");
      const isFuture = this.$moment().isBefore(this.$moment(date), "day");
      if (isFirstInjectionAfter && isPast) {
        // 투약기록 확인하여 데이터 응답받기 (투약기록 있거나, 휴약일일경우)
        const data = this.calendar.searchInjectionData(this.calendarList, date);
        console.log(data);
        if (data && data.isBreakDay) {
          this.isBreakDay = true;
          return;
        }
        const isNotInjected =
          data && data.injection_index && data.isInjectionStatus === "default"; // 투약하지않음 저장할경우
        const isInjected =
          data &&
          (data.isInjectionStatus === "complete" ||
            data.isInjectionStatus === "incomplete");
        if (isNotInjected) {
          this.injectionIndex = data.injection_index; // 투약하지않음 저장할경우
        }
        if (isInjected) {
          const injectionSite = {
            injected_body_part: data.injected_body_part,
            injected_body_part_2nd: data.injected_body_part_2nd,
            injection_dose: data.injection_dose,
            injection_dose_2nd: data.injection_dose_2nd,
          };
          const todayGrowth = {
            injection_review: data.injection_review,
            injection_emoticon: data.injection_emoticon,
          };
          this.injectionSite = injectionSite;
          this.todayGrowth = todayGrowth;
          this.injectionIndex = data.injection_index;
        } else {
          this.showInjectBtn = true;
        }
      }
      if (isFirstInjectionAfter && isPresent) {
        // 투약기록 확인하여 데이터 응답받기 (투약기록 있거나, 휴약일일경우)
        const data = this.calendar.searchInjectionData(this.calendarList, date);
        console.log(data);
        const isNotInjected =
          data && data.injection_index && data.isInjectionStatus === "default"; // 투약하지않음 저장할경우
        const isInjected =
          data &&
          (data.isInjectionStatus === "complete" ||
            data.isInjectionStatus === "incomplete");
        if (isNotInjected) {
          this.injectionIndex = data.injection_index; // 투약하지않음 저장할경우
        }
        if (data && data.isBreakDay) {
          this.isBreakDay = true;
          return;
        } else if (isInjected) {
          const injectionSite = {
            injected_body_part: data.injected_body_part,
            injected_body_part_2nd: data.injected_body_part_2nd,
            injection_dose: data.injection_dose,
            injection_dose_2nd: data.injection_dose_2nd,
          };
          const todayGrowth = {
            injection_review: data.injection_review,
            injection_emoticon: data.injection_emoticon,
          };
          this.injectionSite = injectionSite;
          this.todayGrowth = todayGrowth;
          this.injectionIndex = data.injection_index;
        } else {
          this.toBeInject = true;
          this.showInjectBtn = true;
        }
      }
      if (isFirstInjectionAfter && isFuture) {
        // 투약기록 확인하여 데이터 응답받기 (투약기록 있거나, 휴약일일경우)
        const data = this.calendar.searchInjectionData(this.calendarList, date);
        if (data && data.isBreakDay) {
          this.isBreakDay = true;
          return;
        } else {
          this.toBeInject = true;
        }
      }
    },
    bindDataSet(injectionLog) {
      // 투약기록 바인딩
      this.startDay = injectionLog.startDay;
      this.endDay = injectionLog.endDayToNow;
      this.injectedDaysCount = injectionLog.injectedDaysCount;
      this.expectedDaysCount = injectionLog.expectedDaysCountToNow;
      this.monthSummaryPercent = injectionLog.monthSummaryPercentToNow;
    },
    async getInjectionLogData() {
      const reqData = {
        userIndex: this.$store.getters.getUserIndex,
        targetYear: moment().format("YYYY"),
        targetMonth: moment().format("M"),
      };
      const [injectionLog] = await getInjectionLog(reqData);
      this.bindDataSet(injectionLog);
    },
    async getLevelUpData() {
      const [res] = await this.$store.dispatch("getLevelPopupData");
      if (res) {
        this.characterLevel = res.user_character_level;
        this.starLevel = res.user_star_level;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.diary {
  .content-intro {
    p {
      line-height: 20px;
      img {
        height: 20px;
        vertical-align: middle;
        margin-top: -5px;
      }
    }
  }
}
.progress-graph {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  .v-progress-circular__info {
    font-size: 24px;
    color: #000000;
    span {
      font-size: 16px;
    }
  }
}
.calendar-area {
  .v-picker {
    .v-date-picker-header {
      width: 240px;
      margin: 0 auto;
      padding: 0 16px;
      .theme--light.v-btn.v-btn--icon {
        color: $primary;
      }
    }
    .v-date-picker-table {
      height: 360px;
    }
    td {
      width: 50px;
      height: 50px;
      .v-btn {
        &.v-btn--active {
          color: #000000;
          background-color: transparent !important;
          // outline: 2px solid $secondary;
          box-shadow: 0 0 0 2px $secondary;
          border-radius: 50%;
        }
        &:before {
          opacity: 1;
          background: #d9d9d9;
        }
        .v-btn__content {
          font-size: 10px;
          margin-top: -45px;
          color: #828282;
        }
        .v-date-picker-table__events {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          > .cartridge-change {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0;
            display: block;
            &::after {
              content: "";
              position: absolute;
              bottom: -7px;
              right: -7px;
              width: 20px;
              height: 20px;
              margin: 0;
              display: block;
              background: url(~@/assets/images/calendar/cartridge_change.png)
                no-repeat;
              background-size: 20px;
            }
          }
          > .complete {
            width: 100%;
            height: 100%;
            margin: 0;
            display: block;
            background: url(~@/assets/images/calendar/complete.png) no-repeat;
            background-size: 32px;
          }
          > .break {
            width: 100%;
            height: 100%;
            margin: 0;
            display: block;
            background: url(~@/assets/images/calendar/break.png) no-repeat;
            background-size: 32px;
          }
          > .missing {
            width: 100%;
            height: 100%;
            margin: 0;
            display: block;
            background: url(~@/assets/images/calendar/missing.png) no-repeat;
            background-size: 32px;
          }
          > .parting {
            width: 100%;
            height: 100%;
            margin: 0;
            display: block;
            background: url(~@/assets/images/calendar/parting.png) no-repeat;
            background-size: 32px;
          }
        }
      }
    }
  }
}
.inject-detail {
  .date {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  li {
    margin-bottom: 10px;
    font-size: 14px;
    color: #555555;
    h6 {
      color: #555555;
      margin-bottom: 0;
    }
    b {
      color: #000000;
      font-size: 16px;
    }
    img {
      width: 20px;
      margin-left: 5px;
    }
  }
}
</style>