<template>
  <v-dialog v-model="dialog" content-class="popup index-calendar">
    <v-card-title>싸이젠 투약 다이어리 INDEX</v-card-title>
    <div class="popup-content">
      <v-list subheader two-line>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/images/calendar/complete.png" alt="" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> 주사 완료</v-list-item-title>

            <v-list-item-subtitle>
              하루 처방용량과 동일하게 투여한 경우
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/images/calendar/parting.png" alt="" />
          </v-list-item-avatar>

          <v-list-item-content>
            <!-- <v-list-item-title> 부분주사 </v-list-item-title> -->

            <v-list-item-subtitle>
              하루처방 용량과 다르게 투여한 경우
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/images/calendar/missing.png" alt="" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>주사 놓침</v-list-item-title>

            <v-list-item-subtitle>
              투약 기록을 하지 않았거나, 투약하지 않음으로 기록한 경우
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/images/calendar/break.png" alt="" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> 휴약일</v-list-item-title>

            <v-list-item-subtitle> 주사를 맞지 않는 날 </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/images/calendar/complete.png" alt="" />

            <img
              class="change"
              src="@/assets/images/calendar/cartridge_change.png"
              alt=""
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> 카트리지 교체일</v-list-item-title>

            <v-list-item-subtitle>
              싸이젠 카트리지를 교체한 날
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.index-calendar {
  .v-card__title {
    padding: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 16px;
  }
  .v-list {
    .v-list-item {
      padding: 0;
      .v-avatar {
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
        overflow: inherit;
        .change {
          position: absolute;
          bottom: -4px;
          right: -7px;
          width: 18px;
          height: 18px;
        }
      }
      .v-list-item__content {
        padding: 0;
        .v-list-item__title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
        }
        .v-list-item__subtitle {
          font-size: 14px;
          overflow: inherit;
          white-space: inherit;
          line-height: 16px;
        }
      }
    }
  }
}
</style>