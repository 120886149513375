<template>
  <v-dialog
    v-model="computedOpenCloseStatus"
    content-class="popup common"
    persistent
  >
    <div class="popup-content">
      <p>{{ text }}</p>
      <v-btn color="secondary" class="w-100" depressed @click="clickConfirmBtn">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    openCloseStatus: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedOpenCloseStatus: {
      get() {
        return this.openCloseStatus;
      },
      set(newValue) {
        return this.$emit("onchangeOpenCloseStatus", newValue);
      },
    },
  },
  methods: {
    clickConfirmBtn() {
      this.$emit("clickCloseBtn");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.common {
  background: #ffffff;
  padding-top: 15px;
  .popup-content {
    padding: 20px;
  }
}
</style>