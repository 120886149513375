<template>
  <div class="report page">
    <div class="top-header">
      <button class="header-btn btn-back" @click="clickBackBtn">
        <v-icon large> mdi-chevron-left</v-icon>
      </button>
      <h1 class="page-title">성장일기 리포트</h1>
    </div>
    <div class="page-content">
      <div class="set-term">
        <h2 class="page-sub-title">조회 기간 설정하기</h2>

        <v-row>
          <v-col cols="12">
            <DatePickerRange
              :dates="dates"
              label="직접선택"
              @onchange="onchangeDates"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn-toggle
              v-model="autoTerm"
              @change="onchangeAutoTerm"
              tile
              group
            >
              <v-btn class="btn-term" outlined fab> 1개월 </v-btn>
              <v-btn class="btn-term" outlined fab> 3개월 </v-btn>
              <v-btn class="btn-term" outlined fab> 6개월 </v-btn>
              <v-btn class="btn-term" outlined fab> 1년 </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <p class="row-guide">
          리포트는 현재일 기준으로 이전 월까지 기록을 보실 수 있습니다. <br />
          예: 현재 12월 2일 경우, 11월 31일까지 기록만 확인 가능
        </p>
        <v-row class="mt-5 mb-5">
          <v-col cols="6">
            <v-btn
              color="secondary"
              class="w-100 btn-cancel"
              @click="clickCancelBtn"
              depressed
            >
              취소
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              class="w-100"
              @click="clickInquiryBtn"
              depressed
            >
              조회
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <h5 id="reportTitle" class="report-title mb-5">
        리포트 기간 : <b>{{ computedReportDate }}</b>
      </h5>
      <div class="grey-card mt-4 mb-4 report-summary">
        <ReportRecord
          :injectedDaysCount="injectedDaysCount"
          :expectedDaysCount="expectedDaysCount"
          :increaseHeight="increaseHeight"
          :summaryPercent="summaryPercent"
        />
      </div>
      <div class="grey-card mt-4 mb-4 index-area">
        <h6 class="c-sec">Index</h6>
        <span class="index-item">
          <img src="@/assets/images/calendar/complete.png" alt="" />
          주사완료
        </span>
        <span class="index-item">
          <img src="@/assets/images/calendar/parting.png" alt="" />
          부분주사
        </span>
        <span class="index-item">
          <img src="@/assets/images/calendar/missing.png" alt="" />
          주사 놓침
        </span>
        <span class="index-item">
          <img src="@/assets/images/calendar/break.png" alt="" />
          휴약일
        </span>
        <span class="index-item cartridge-change">
          <img src="@/assets/images/calendar/complete.png" alt="" />
          카트리지 교체일
        </span>
      </div>
      <div class="calendar-area">
        <CalendarPicker
          :date="date"
          :pickerDate="pickerDate"
          :functionEvents="functionEvents"
          @onchange="onchangeDate"
          @onchangePickerDate="onchangePickerDate"
          :min="minDate"
          :max="maxDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ko";
import InjectionRecord from "@/components/main/InjectionRecord.vue";
import GrowthRecord from "@/components/main/GrowthRecord.vue";
import CalendarPicker from "@/components/common/vuetify/CalendarPicker.vue";
import DatePickerRange from "@/components/common/vuetify/DatePickerRange.vue";
import { getInjectionLog, getInjectionLogList } from "@/api/injectionLog";
import { getLastPrescription } from "@/api/prescription";
import { Calendar } from "@/components/common/model/Calendar";
import ReportRecord from "@/components/report/ReportRecord.vue";
export default {
  components: {
    InjectionRecord,
    GrowthRecord,
    CalendarPicker,
    DatePickerRange,
    ReportRecord,
  },
  data() {
    return {
      calendar: new Calendar(),
      moment,
      date: moment().subtract(1, "month").format("YYYY-MM-DD"), // calendar 날짜
      dates: [], // 상단 기간조회 날짜
      pickerDate: null,
      minDate: null,
      maxDate: null,
      // 자동 기간 선택
      autoTerm: null,

      // 투약 기록
      startDay: null,
      endDay: null,
      injectedDaysCount: null,
      expectedDaysCount: null,
      summaryPercent: 0,
      // 성장 기록
      startedMeasureDay: null,
      increaseHeight: null,

      //  calendar Array
      completeArray: [],
      partingArray: [],
      breakArray: [],
      missingArray: [],
      cartridgeArray: [],
      calendarList: [],

      firstInjectionDate: null,
    };
  },
  watch: {
    dates(value) {
      // [시작일, 종료일] 다 입력되었을때
      if (value.length == 2) {
        this.modifiedDates(value);
      }
    },
  },
  computed: {
    computedReportDate() {
      return this.dates.join(" ~ ");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    clickBackBtn() {
      this.$router.push("app");
    },
    clickInquiryBtn() {
      if (this.dates.length == 2) {
        this.setCalendar(this.dates);
        this.getReportRecord();
        this.inquiryReportScroll();
      }
    },
    inquiryReportScroll() {
      const report = document.getElementById("reportTitle");
      report.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    clickCancelBtn() {
      this.$router.push("app");
      // this.init();
    },
    onchangeAutoTerm(value) {
      if (value == undefined) {
        this.minDate = null;
        this.maxDate = null;
      }
      this.setDates(value);
    },
    onchangeDates(value) {
      this.autoTerm = null;
      this.dates = value;
    },
    onchangeDate(value) {
      this.date = value;
    },
    onchangePickerDate(value) {
      this.pickerDate = value;
      if (!value) return;
      const targetYear = this.$moment(value).format("YYYY");
      const targetMonth = this.$moment(value).format("MM");
      this.getCalenderData(targetYear, targetMonth);
    },
    init() {
      this.onchangeAutoTerm(0);
      this.autoTerm = 0;
      this.setCalendar(this.dates);
      this.getReportRecord();
    },
    modifiedDates(value) {
      // 이때 투약정보, 및 등 조회하여 캘린더에 반영해야함.
      // 선택된 날짜만 볼수 있도록 처리
      // this.setCalendar(value); // 날짜 선택시 조회 x , 조회버튼 클릭시 조회로 변경
    },
    setReportRecord(res) {
      // 투약기록 바인딩
      this.injectedDaysCount = res.injectedDaysCount;
      this.expectedDaysCount = res.expectedDaysCount;
      this.summaryPercent = res.summaryPercent;
      // 성장기록 바인딩
      this.increaseHeight = res.increaseHeight;
    },
    async getReportRecord() {
      try {
        const reqData = {
          fromYear: this.$moment(this.dates[0]).format("YYYY"),
          fromMonth: this.$moment(this.dates[0]).format("M"),
          toYear: this.$moment(this.dates[1]).format("YYYY"),
          toMonth: this.$moment(this.dates[1]).format("M"),
        };
        const [injection] = await this.$store.dispatch(
          "getReportInjection",
          reqData
        );
        const [growRecord] = await this.$store.dispatch(
          "getReportGrowRecord",
          reqData
        );
        this.setReportRecord({ ...injection, ...growRecord });
      } catch (error) {
        console.log(error);
      }
    },
    setCalendar(value) {
      const [fromDay, toDay] = value;
      this.date = fromDay;
      this.pickerDate = fromDay;

      this.minDate = this.moment(fromDay).startOf("month").format("YYYY-MM-DD");
      this.maxDate = this.moment(toDay).endOf("month").format("YYYY-MM-DD");
    },
    setDates(value) {
      // (value) 0: 1개월, 1: 3개월, 2: 6개월, 3: 1년
      if (value == undefined || value == null) return;
      let startDay = this.$moment();
      let endDay = this.moment().subtract(1, "month").endOf("month");
      switch (value) {
        case 0:
          startDay = this.moment().subtract(1, "month").startOf("month");
          break;
        case 1:
          startDay = this.moment().subtract(3, "month").startOf("month");
          break;
        case 2:
          startDay = this.moment().subtract(6, "month").startOf("month");
          break;
        case 3:
          startDay = this.moment().subtract(1, "year").startOf("month");
          break;
      }
      this.dates = [startDay.format("YYYY-MM"), endDay.format("YYYY-MM")];
    },
    PageTo(url) {
      this.$router.push(url);
    },
    async getCalenderData(targetYear = null, targetMonth = null) {
      const year = targetYear ? targetYear : this.$moment().format("YYYY");
      const month = targetMonth ? targetMonth : this.$moment().format("MM");
      const date = `${year}-${month}`;
      const variable = {
        userIndex: this.$store.getters.getUserIndex,
        targetYear: year,
        targetMonth: month,
      };
      const InjectionLogList = await getInjectionLogList(variable);
      const PrescriptionList = await getLastPrescription(variable);
      const myInfo = await this.$store.dispatch("getMyInfo");

      this.firstInjectionDate = myInfo.first_injection_date;
      // 날짜 넘겨받기
      this.initCalendar(
        date,
        InjectionLogList,
        PrescriptionList,
        this.firstInjectionDate
      );
    },
    initCalendar(date, InjectionLogList, PrescriptionList, firstInjectionDate) {
      const calendarList = this.calendar.setCalendarList(date);
      const updateList = this.calendar.updateCalendarList(
        calendarList,
        InjectionLogList,
        PrescriptionList
      );
      this.bindCalendar(updateList, firstInjectionDate);
    },
    bindCalendar(updateList, firstInjectionDate) {
      // 초기화
      this.completeArray = [];
      this.partingArray = [];
      this.breakArray = [];
      this.missingArray = [];
      this.cartridgeArray = [];

      this.calendarList = updateList;
      const isMissingDay = (item) => {
        const day =
          String(item.day).length === 1 ? 0 + String(item.day) : item.day; // 이유: moment에 입력할때 2022-08-9 로 입력하게 되면 warning 문구 발생
        return (
          item.isInjectionStatus === "default" &&
          !item.isBreakDay &&
          this.$moment(firstInjectionDate).isSameOrBefore(
            this.$moment(`${item.year}-${item.month}-${day}`)
          ) && // 주사시작일부터 반영
          this.$moment().isAfter(
            this.$moment(`${item.year}-${item.month}-${day}`),
            "day"
          )
        );
      };
      const isBreakDay = (item) => {
        const day =
          String(item.day).length === 1 ? 0 + String(item.day) : item.day; // 이유: moment에 입력할때 2022-08-9 로 입력하게 되면 warning 문구 발생
        return (
          item.isBreakDay &&
          this.$moment(firstInjectionDate).isSameOrBefore(
            this.$moment(`${item.year}-${item.month}-${day}`)
          )
          // 주사시작일부터 반영
        );
      };

      // 카트리지 교체일
      updateList.filter((item) => {
        if (item.isReplaceCartridge) this.cartridgeArray.push(item.day);
      });
      // 주사맞음 설정
      updateList.filter((item) => {
        if (item.isInjectionStatus === "complete")
          this.completeArray.push(item.day);
      });
      // // 부분주사
      updateList.filter((item) => {
        if (item.isInjectionStatus === "incomplete")
          this.partingArray.push(item.day);
      });
      // 주사 놓침
      updateList.filter((item) => {
        if (isMissingDay(item)) this.missingArray.push(item.day);
      });
      // 휴약일 설정
      updateList.filter((item) => {
        if (isBreakDay(item)) this.breakArray.push(item.day);
      });
    },

    bindDataSet(injectionLog, bodyInfo) {
      // 투약기록 바인딩
      this.startDay = injectionLog.startDay;
      this.endDay = injectionLog.endDay;
      this.injectedDaysCount = injectionLog.injectedDaysCount;
      this.expectedDaysCount = injectionLog.expectedDaysCount;
      // 성장기록 바인딩
      this.startedMeasureDay = bodyInfo.startedMeasureDay;
      this.increaseHeight = bodyInfo.increaseHeight;
    },
    functionEvents(date) {
      // console.log(date);
      const [, , day] = date.split("-");
      if (this.partingArray.includes(parseInt(day))) {
        if (this.cartridgeArray.includes(parseInt(day))) {
          return ["parting cartridge-change", ""];
        } else {
          return ["parting", ""];
        }
      }
      if (this.completeArray.includes(parseInt(day))) {
        if (this.cartridgeArray.includes(parseInt(day))) {
          return ["complete cartridge-change", ""];
        } else {
          return ["complete", ""];
        }
      }
      if (this.breakArray.includes(parseInt(day))) return ["break", ""];
      if (this.missingArray.includes(parseInt(day))) return ["missing", ""];
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.report {
  height: 100%;
  .page-content {
    height: 100%;
    overflow: auto;
  }
  .set-term {
    .v-btn-toggle--group {
      text-align: center;
      display: block;
      .btn-term {
        height: 30px !important;
        width: calc(25% - 8px);
        border-radius: 20px !important;
        margin: 0 10px 0 0;
        border: none;
        color: $secondary !important;
        background-color: #f8f8f8 !important;
        opacity: 1;
        &:last-child {
          margin: 0;
        }
        &.v-item--active {
          background: $secondary !important;
          color: #ffffff !important;
        }
        &::before {
          opacity: 0;
        }
      }
    }
    .btn-cancel {
      background-color: #c8c8c8 !important;
    }
  }
  .report-title {
    font-size: 16px;
    padding-top: 40px;
    b {
      color: $secondary;
    }
  }
  .report-summary {
    > .row {
      align-items: start;
      margin-bottom: 0 !important;
    }
    .col-title {
      font-weight: 700;
      text-align: center;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .planned-num {
      margin-left: -5px;
    }
    .grow-num {
      font-size: 42px;
      font-weight: 700;
      color: $secondary;
      line-height: 42px;
      text-align: center;
      .v-icon {
        color: $secondary;
        margin-left: -10px;
      }
      span {
        font-size: 18px;
        font-weight: 400;
      }
    }
    .grow-unit {
      font-size: 26px;
      font-weight: 300;
      padding: 0 calc(50% - 55px) 0 0 !important;
      text-align: center;
      justify-content: center;
    }
    .summary-percent-num {
      font-size: 42px;
      font-weight: 700;
      line-height: 42px;
      display: flex;
      color: #ffd363;
      // justify-content: flex-end;
      padding: 0 0 0 calc(50% - 65px) !important;
      text-align: center;
      justify-content: center;
    }
  }
  .index-area {
    .index-item {
      display: inline-block;
      min-width: 33.3%;
      font-weight: 600;
      padding: 0 10px 10px 0;
      &.cartridge-change {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 7px;
          left: 19px;
          width: 18px;
          height: 18px;
          margin: 0;
          display: block;
          background: url(~@/assets/images/calendar/cartridge_change.png)
            no-repeat;
          background-size: 18px;
        }
      }
      img {
        width: 25px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
  .calendar-area {
    .v-picker {
      .v-date-picker-header {
        width: 240px;
        margin: 0 auto;
        padding: 0 16px;
        .theme--light.v-btn.v-btn--icon {
          color: $primary;
        }
      }
      .v-date-picker-table {
        height: 360px;
      }
      td {
        width: 50px;
        height: 50px;
        .v-btn {
          &.v-btn--active {
            color: #000000;
            background-color: transparent !important;
            // outline: 2px solid $secondary;
            box-shadow: 0 0 0 2px $secondary;
            border-radius: 50%;
          }
          &:before {
            opacity: 1;
            background: #d9d9d9;
          }
          .v-btn__content {
            font-size: 10px;
            margin-top: -45px;
            color: #828282;
          }
          .v-date-picker-table__events {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            > .cartridge-change {
              position: relative;
              width: 100%;
              height: 100%;
              margin: 0;
              display: block;
              &::after {
                content: "";
                position: absolute;
                bottom: -7px;
                right: -7px;
                width: 20px;
                height: 20px;
                margin: 0;
                display: block;
                background: url(~@/assets/images/calendar/cartridge_change.png)
                  no-repeat;
                background-size: 20px;
              }
            }
            > .complete {
              width: 100%;
              height: 100%;
              margin: 0;
              display: block;
              background: url(~@/assets/images/calendar/complete.png) no-repeat;
              background-size: 32px;
            }
            > .break {
              width: 100%;
              height: 100%;
              margin: 0;
              display: block;
              background: url(~@/assets/images/calendar/break.png) no-repeat;
              background-size: 32px;
            }
            > .missing {
              width: 100%;
              height: 100%;
              margin: 0;
              display: block;
              background: url(~@/assets/images/calendar/missing.png) no-repeat;
              background-size: 32px;
            }
            > .parting {
              width: 100%;
              height: 100%;
              margin: 0;
              display: block;
              background: url(~@/assets/images/calendar/parting.png) no-repeat;
              background-size: 32px;
            }
          }
        }
      }
    }
  }
}
</style>
