<template>
  <div>
    <VDatetimeField
      ref="time"
      v-model="computedTime"
      only-time
      flat
      :time-props="{
        outlined: true,
        dense: true,
        flat: true,
        hideDetails: true,
        noTitle: true,
        label: label,
        appendIcon: 'mdi-clock-time-four-outline',
      }"
    />
  </div>
</template>
<script>
import { VDatetimeField } from "v-datetime-field";
export default {
  components: { VDatetimeField },
  props: {
    label: {
      type: String,
      default: "Time",
    },
    time: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    computedTime: {
      get() {
        return this.time;
      },
      set(newValue) {
        console.log(newValue);
        let value = newValue;
        const lazyValue = this.$refs.time.$refs.timePickerInput.lazyValue;
        const isValid =
          this.$moment(lazyValue, "hh:mm").isValid() && lazyValue.length == 5;
        if (!isValid) value = "";
        this.$refs.time.$refs.timePickerInput.lazyValue = value;
        return this.$emit("onchange", value);
      },
    },
  },
  methods: {
    clickMinute(value) {
      this.$refs.menu.save(value);
    },
  },
};
</script>

<style lang="scss">
.v-datetime-field .v-datetime-field__wrapper {
  display: block;
  margin-top: 0;
  .v-text-field__slot {
    position: absolute !important;
    height: 100%;
    width: 100%;
    z-index: 100;
  }
}
.v-menu__content:has(.v-picker--time) {
  display: none;
}
// .v-picker--time {
//   display: none;
//   .v-picker__actions {
//     display: none;
//   }
// }
</style>
