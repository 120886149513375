<template>
  <v-row>
    <!-- 투약 기록 -->
    <v-col cols="4" class="progress-text">
      <div class="col-title mb-2">투약 기록</div>
      <v-row>
        <v-col cols="12" class="c-prm inject-num">{{
          injectedDaysCount
        }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="planned-num">/ {{ expectedDaysCount }}</v-col>
      </v-row>
    </v-col>

    <v-divider vertical></v-divider>
    <!-- 성장 기록 -->
    <v-col cols="4" class="progress-text">
      <div class="col-title mb-2 ml-1">성장 기록</div>
      <v-row class="grow-num">
        <v-col cols="12">
          <v-icon v-if="Number(increaseHeight) >= 0">mdi-plus</v-icon>
          <v-icon v-else>mdi-minus</v-icon>{{ computedIncreaseHeight || "0" }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="grow-unit"><span>cm</span></v-col>
      </v-row>
    </v-col>

    <v-divider vertical></v-divider>
    <!-- 순응도 -->
    <v-col cols="4" class="progress-text">
      <div class="col-title mb-2 ml-1">순응도</div>
      <v-row cols="12" class="summary-percent-num">{{
        summaryPercent || "0"
      }}</v-row>
      <v-row cols="12" class="planned-num">%</v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    injectedDaysCount: {
      type: Number,
      default: null,
    },
    expectedDaysCount: {
      type: Number,
      default: null,
    },
    increaseHeight: {
      type: String,
      default: "0",
    },
    summaryPercent: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    computedIncreaseHeight() {
      return Number(this.increaseHeight) < 0
        ? Math.abs(this.increaseHeight)
        : this.increaseHeight;
    },
  },
};
</script>